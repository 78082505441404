import React, { useState } from "react";
import styled from "styled-components";
import WhoButton from "../Home/Whoweare/WhoButton";
import {useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import FooterButton from "../Home/Footer/FooterButton";

const WorkContent = () => {
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string().required("Number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
});

const initialValues = {
  name: "",
  phone: "",
  email: "",
  message: "",
};


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [rejected, setRejected] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleButtonClicked = () => {
    openModal();
  };

  const setBack = () => {
    setTimeout(() => {
      setAccepted(false);
      setRejected(false);
    }, 3000); // Delay in milliseconds before resetting the state
  };

  const serviceId = "service_30uxbrh";
  const templateId = "template_gbbm8g7";
  const publicKey = "CTSCzUkf6yTQjxIar";

  const onSubmit = async (values, { resetForm }) => {
    const emailjs_data = {
      service_id: serviceId,
      template_id: templateId,
      user_id: publicKey,
      template_params: {
        name: values.name,
        phone: values.phone,
        email: values.email,
        message: values.message,
      },
    };
    // console.log(emailjs_data);
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.emailjs.com/api/v1.0/email/send",
        emailjs_data
      );

      setLoading(false);
      setAccepted(true);
      resetForm();
      setBack();
      // closeModal();
    } catch (error) {
      // console.log(error);
      setLoading(false);
      setRejected(true);
      setBack()
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <WorkCont>
      <div className="container div py-5">
        <div className="grid">
          <div className="grid-item">
            <IframeContainer>
            <iframe  src="https://www.youtube.com/embed/HVlLMQxCZ9I?si=xM6kN0bjj6JeB5v9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
           
            </IframeContainer>
          </div>
          <div className="grid-item">
            <IframeContainer>
            <iframe  src="https://www.youtube.com/embed/SEN1_j_tyuk?si=EDy8vYLIw5ba98C-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </IframeContainer>
          </div>
          <div className="grid-item">
            <IframeContainer>
            <iframe  src="https://www.youtube.com/embed/Jz9IdpBbQYo?si=SypRMNfa5Gv98mIE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </IframeContainer>
          </div>
          <div className="grid-item">
            <IframeContainer>
            <iframe src="https://www.youtube.com/embed/o0aNS7FL-cc?si=X6YtW6JvoXz1QhwJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </IframeContainer>
          </div>
          <div className="grid-item">
            <IframeContainer>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/dVlPBwvs65g?si=2vfjcBj-oOA1sn4j" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </IframeContainer>
          </div>
          <div className="grid-item belong">
            <div className="text">
              <p>
                Your
                <br />
                Dream
                <br />
                Belongs <br />
                Here
              </p>
            </div>
            <div className="button">
              <WhoButton title="Send us a Brief" onClick={handleButtonClicked}/>
            </div>
          </div>
          <div className="grid-item">
            <img
              src="https://res.cloudinary.com/wiservedigital/image/upload/v1654093778/7EVEN%20WEBSITE/Featured%20Page/12_-_COFFEE_RHUM_el9chr.jpg"
              alt="data"
            />
          </div>
          <div className="grid-item">
            <IframeContainer>
              <iframe
                src="https://www.youtube.com/embed/w_Ei37hbwsc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              ></iframe>
            </IframeContainer>
          </div>
          <div className="grid-item">
            <img
              src="https://res.cloudinary.com/wiservedigital/image/upload/v1654093777/7EVEN%20WEBSITE/Featured%20Page/15_-_THE_9JA_SPIRIT_IS_WITHIN_ccb5ul.jpg"
              alt="data"

            />
          </div>
          <div className="grid-item belong2">
            <div className="text">
              <p>
                Your
                <br />
                Dream
                <br />
                Belongs <br />
                Here
              </p>
            </div>
            <div className="button">
              <WhoButton title="Send us a Brief" onClick={handleButtonClicked}/>
            </div>
          </div>
          <div className="grid-item">
            <IframeContainer>
              <iframe
                src="https://www.youtube.com/embed/uKIh9d9BHkY"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              ></iframe>
            </IframeContainer>
          </div>
          <div className="grid-item">
            <IframeContainer>
              <iframe
                src="https://www.youtube.com/embed/-PI-R4ddEp4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              ></iframe>
            </IframeContainer>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal>
          <ModalContent>
            <CloseButton onClick={closeModal}>x</CloseButton>
            <h2>Brief Details</h2>
            <div className="form">
              <input
                type="text"
                name="name"
                placeholder="Name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <div className="error">{formik.errors.name}</div>
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
              <div className="error">{formik.errors.phone}</div>
              <input
                type="email"
                name="email"
                placeholder="Email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              <div className="error">{formik.errors.email}</div>
              <textarea
                as="textarea"
                rows="4"
                name="message"
                onChange={formik.handleChange}
                value={formik.values.message}
                placeholder="Message"
              />
              <div className="error">{formik.errors.message}</div>

              <div className="center">
                <FooterButton
                  title={loading ? "Loading..." : "Submit"}
                  onClick={formik.handleSubmit}
                />
                {accepted && <p className="green">Form Submitted !!!</p>}
                {rejected && (
                  <p className="error2">An Error occured. Try again !!!</p>
                )}
              </div>
            </div>
          </ModalContent>
        </Modal>
      )}
    </WorkCont>
  );
};

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: #030c2d;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  position: relative;
  border: 0.2px solid white;

  h2 {
    /* margin-bottom: 20px; */
    color: white;
    font-family: "MarkPro", Times;
  }
  .center {
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;

  }
  .form {
    display: flex;
    flex-direction: column;

    .error {
      color: red;
      text-align: left;
      margin-bottom: 5px;
      font-family: "MavenMedium", Times;
    }

    .error2 {
      color: red;
      text-align: center;
      margin-top: 10px;
      font-family: "MavenRegular", Times;
    }

    .green {
      color: green;
      text-align: center;
      margin-top: 10px;
      font-family: "MavenRegular", Times;
    }
    input,
    textarea,
    select {
      margin-bottom: 5px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
      font-family: "MavenRegular", sans-serif;
      /* color: #030C2D; */
    }
  }
  @media (max-width: 540px) {
    /* background: wh; */
    padding: 10px;
    border-radius: 5px;
    width: 80%;
    text-align: center;
    position: relative;
    .error {
      text-align: left;
      font-size: 12px;
    }
    .error2 {
      font-size: 12px!important;
    }
    .green {
      font-size: 12px!important;
    }
    .form {
      display: flex;
      flex-direction: column;

      input,
      textarea,
      select {
        margin-bottom: 5px;
        padding: 5px 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 14px;
      }
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 15px;
  background: none;
  border: none;
  font-size: 32px;
  cursor: pointer;
  color: white;
  font-family: "MavenMedium", Times;
  @media (max-width: 540px) {
  font-size: 25px;

  }
`;
const IframeContainer = styled.div`
  iframe {
    width: 100%; /* Set your desired width here */
    height: 315px; /* Set your desired height here */
  }

  @media (max-width: 1024px) and (max-height: 600px) {
    iframe {
      width: 300px;
      height: 200px;
    }
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    iframe {
      width: 300px; /* Set your desired width here */
      height: 200px; /* Set your desired height here */
    }
  }
  @media (max-width: 912px) {
    /* For tablet-sized screens */
    iframe {
      width: 300px; /* Set your desired width here */
      height: 200px; /* Set your desired height here */
    }
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    iframe {
      width: 100%; /* Set your desired width here */
      height: 350px; /* Set your desired height here */
    }
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    iframe {
      width: 100%; /* Set your desired width here */
      height: 250px; /* Set your desired height here */
    }
  }
`;
const WorkCont = styled.div`
  // width:100%;
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
  .grid-item {
    height: 100%;
    width: 100%;
  }

  p {
    color: #030c2d;
    font-family: "MarkPro", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.72px;
    text-transform: capitalize;
  }
  .belong {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
  }
  .belong2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
  }
  img {
    width: 100%;
    height: 315px;
  }
  @media (max-width: 1280px) {
    img {
      width: 100%;
      height: 315px;
    }
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1rem;
    }
    .belong {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
    }
    .belong2 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
    }
    p {
      font-size: 25px;
    }
  }

  @media (max-width: 1024px) and (max-height: 600px) {
    img {
      width: 300px;
      height: 200px;
    }
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1rem;
    }
    .belong {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
    }
    .belong2 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
    }
    p {
      font-size: 25px;
    }
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    img {
      width: 300px;
      height: 200px;
    }
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1rem;
    }
    .belong {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
    }
    .belong2 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
    }
    p {
      font-size: 25px;
    }
  }

  @media (max-width: 912px) {
    img {
      width: 300px;
      // height:250px;
      height: 200px;
    }
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }
    .belong {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
    }
    .belong2 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
    }
    p {
      font-size: 25px;
    }
  }

  @media (max-width: 640px) {
    img {
      width: 100%;
      height: 350px;
    }
    .grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
    }
    .belong {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
    }
    .belong2 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
    }
  }

  @media (max-width: 480px) {
    .grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
    }
    img {
      width: 100%;
      height: 250px;
    }
    p {
      font-size: 35px;
    }
  }
`;

export default WorkContent;

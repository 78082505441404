import React, { useEffect,useState } from "react";
import { motion} from "framer-motion"; // Import useAnimation
import styled from "styled-components";
import Navbar from "../../Navbar/Navbar";

const LandingSection = () => {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 5000);
  }, []);

  return showLoader ?(
      <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
 <LandBrief>
      <Navbar />
      <div className="container div">
        <div className="pdiv">
          <p className="text">
            We Use Data To Power Ideas,<br/> Enabled By Technology
          </p>
        </div>
      </div>
    </LandBrief>
    </motion.div>
):(
  <motion.div
  initial={{ opacity: 0.5 }}
  animate={{ opacity: 1 }}
  exit={{ opacity: 0 }}
>
  <Land>
  <Navbar />
  <div className="container div">
    <div className="img-container">
      <div>
        <img src="/img/data.png" alt="data" />
      </div>
      <div>
        <img src="/img/idea.png" alt="idea" />
      </div>
      <div>
        <img src="/img/technology.png" alt="tech" />
      </div>
    </div>
    <div className="pdiv">
      <p className="text">
        We Use Data To Power Ideas, Enabled By Technology
      </p>
    </div>
  </div>
</Land>
</motion.div>

)
};


const Land = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/bglanding2.png);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10rem;

    img {
      height: 10rem;
      width: auto;
    }
  }
  .pdiv {
    display: none;
    margin-top: 30px;
  }
  .text {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.015em;
    text-transform: capitalize;
    color: white;
    /* font-family: "Maven Pro", sans-serif;
     */
    font-family: "MarkPro", sans-serif;

  }

  @media (max-width: 1280px) {
    /* For laptop-sized screens */
  }

  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh !important;
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 50vh;
  }

  @media (max-width: 912px) {
    /* For tablet-sized screens */
    height: 50vh;
    .img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      img {
        width: auto;
        height: 7rem;
      }
    }
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    height: 50vh;
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    background-image: url(${process.env.PUBLIC_URL}/img/bglanding2.png);
    background-size: cover;
    background-position: center;
    height: 100vh;
    .pdiv {
      display: block;
      padding: 0px 40px;
    }
    .text {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.015em;
      text-transform: capitalize;
      color: white;
    }
    .img-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      img {
        width: auto;
        height: 7rem;
      }
    }
  }
`;

const LandBrief = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/bgfeatured.png);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .text {
    font-weight: 400;
    font-size: 45px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.015em;
    text-transform: capitalize;
    color: white;
    font-family: "MarkPro", sans-serif;

  }

  @media (max-width: 1280px) {
    /* For laptop-sized screens */
  }

  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh !important;
    .text {
        font-size: 26px;
        line-height: 40px;

      }
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 50vh;
    .text {
        font-size: 30px;
    line-height: 40px;

      }
  }

  @media (max-width: 912px) {
    /* For tablet-sized screens */
    height: 50vh;
    .text {
        font-size: 26px;
        line-height: 40px;

      }
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    height: 50vh;
    .text {
        font-size: 24px;
       line-height: 30px;

      }
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    height: 100vh;
    .pdiv {
      display: block;
      padding: 0px 40px;
    }
    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.015em;
      text-transform: capitalize;
      color: white;
    }
   
  }
`;
export default LandingSection;

import styled from "styled-components";
import HeaderText from "../../Text/HeaderText";
import WhoButton from "./WhoButton";
import { NavLink } from "react-router-dom";

const WhoweareSection = () => {
  return (
    <Who>
      <div className="container div">
        <HeaderText
          title="Who We are"
          title1="Who"
          title2="We are"
          color="#001033"
        />
        <WhoContainer>
          <img
            src="/img/whoweare.png"
            className="imgwhoweare"
            alt="whoweareicon"
          />
          <img
            src="/img/mobilewho.png"
            className="imgwhowearemboile"
            alt="whoweareicon"
          />
        </WhoContainer>
        <div className="whobox">
          <div>
            <p>
              The ones who appreciate the beauty of your dreams, but more than
              that, know just how to make them come to life through brilliant
              strategy and creativity that produce incredible ideas and
              innovation.
            </p>
          </div>
          <NavLink
            to="/about"
            // target="_blank"
            // rel="noopener noreferrer"
            className="custom-link"
          >
            <div className="whobutton">
              <WhoButton title="About us" />
            </div>
          </NavLink>
        </div>
      </div>
    </Who>
  );
};

const Who = styled.div`
  // background-image: url(${process.env.PUBLIC_URL}/img/bgaward.png);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .imgwhoweare {
    width: auto;
    height: 300px;
    margin-top: 80px;
  }
  .imgwhowearemboile {
    display: none;
  }
  .custom-link {
    text-decoration: none;
    color: #01eaff;
  }
  .whobox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 80px;

    p {
      text-align: justify;
      font-weight: 400;
      font-size: 20px;
      padding-right: 450px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.015em;
      color: #000723;
      font-family: "MavenRegular", Times;
    }
  }

  @media (max-width: 1200px) {
    /* For laptop-sized screens */
    height: 100vh;
  }
  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh;
    .imgwhoweare {
      width: auto;
      height: 200px;
      // margin-top:80px;
    }
    .whobox {
      display: flex;
      // flex-direction:column;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 50px;

      p {
        text-align: justify;
        font-weight: 400;
        font-size: 20px;
        padding-right: 200px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: -0.015em;
        color: #000723;
      }
    }
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 50vh;
    .imgwhoweare {
      width: auto;
      height: 200px;
      // margin-top:80px;
    }
    .whobox {
      display: flex;
      // flex-direction:column;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 80px;

      p {
        text-align: justify;
        font-weight: 400;
        font-size: 20px;
        padding-right: 200px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: -0.015em;
        color: #000723;
      }
    }
  }
  @media (max-width: 912px) {
    /* For tablet-sized screens */

    height: 50vh;
    .imgwhoweare {
      width: auto;
      height: 150px;
      // margin-top:80px;
    }
    .whobox {
      display: flex;
      // flex-direction:column;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 50px;

      p {
        text-align: justify;
        font-weight: 400;
        font-size: 15px;
        padding-right: 100px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: -0.015em;
        color: #000723;
      }
    }
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    height: 50vh;
    .imgwhoweare {
      width: auto;
      height: 100px;
      margin-top: 20px;
    }
    .whobox {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 20px;

      p {
        text-align: justify;
        font-weight: 400;
        font-size: 16px;
        padding-right: 100px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: -0.015em;
        color: #000723;
      }
    }
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    height: 100vh;
    .imgwhowearemboile {
      display: block;
      width: auto;
      height: 90%;
      position: absolute;
      right: 0;
      // top:10%;
      // bottom:90%;
      z-index: -1;
      margin-top: 35%;
    }

    .imgwhoweare {
      display: none;
    }
    .whobutton {
      margin-top: 30%;
    }

    .whobox {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 20px;

      p {
        text-align: justify;
        font-weight: 400;
        font-size: 20px;
        padding-right: 50px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: -0.015em;
        color: #000723;
      }
    }
  }
`;
const WhoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    /* For laptop-sized screens */
  }

  @media (max-width: 912px) {
    /* For tablet-sized screens */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */

    margin-top: 30%;
  }
`;

export default WhoweareSection;

import styled from "styled-components";
import Button from "./Button";
const SeventhSection = () => {
  return (
    <Seventh>

      <img src="/img/sticker.png" className="imgs" alt="sticker-button" />
      <div className="position">
        <Button title="download stickers"/>
      </div>
    </Seventh>
  );
};

const Seventh = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  //   height: 100vh;

  .position {
    position: absolute;
    right: 16rem;
    bottom: 16rem;
  }

  .join-btn {
    cursor: pointer;
    transition: 0.3s;
  }

  .join-btn:hover {
    transform: scale(1.01);
  }
  @media (max-width: 1600px) {
    height: 100vh;

    .imgs {
      width: auto;
      height: 750px;
    }
    .position {
      position: absolute;
      right: 18%;
      bottom: 21%;
    }
  }
  @media (max-width: 1440px) {
    height: 100vh;

    .imgs {
      width: auto;
      height: 700px;
    }
    .position {
      position: absolute;
      right: 9.5%;
      bottom: 22%;
    }
  }
  @media (max-width: 1280px) {
    height: 100vh;

    .imgs {
      width: auto;
      height: 800px;
    }
    .position {
      position: absolute;
      right: 10%;
      bottom: 22%;
    }
  }

  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh !important;
    .imgs {
      width: auto;
      height: 600px;
    } 
    .position {
    position: absolute;
    right: 8rem;
    bottom: 8rem;
  }

  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 50vh;

    .imgs {
      width: auto;
      height: 700px;
    }

    .position {
      position: absolute;
      right: 4rem;
      bottom: 8rem;
    }
  }

  @media (max-width: 912px) {
    /* For tablet-sized screens */
    height: 50vh;

    .imgs {
      width: 95%;
      height: auto;
    }

    .position {
      position: absolute;
      right: 2.6rem;
      bottom: 8rem;
    }
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    height: 50vh;
    .imgs {
      width: auto;
      height: 360px;
    }

    .position {
      position: absolute;
      right: 2rem;
      bottom: 18%;
    }
  }

  @media (max-width: 480px) {
    height: 50vh;

    .imgs {
      width: 100%;
      height: auto;
    }
    .position {
      position: absolute;
      right: 2%;
      bottom: 75px;
    }
  }
`;

export default SeventhSection;

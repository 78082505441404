import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Intro from "../pages/Intro";
import Home from "../pages/Home";
import About from "../pages/About";
import Work from "../pages/Work";
import 'bootstrap/dist/css/bootstrap.css';
import TheDreamers from "../pages/TheDreamers";
import { AnimatePresence } from "framer-motion";
import 'bootstrap/dist/css/bootstrap.min.css'
import VTC from "../pages/VTC";
import NotFound from "../pages/NotFound";
import VIdeoIntro2 from "../pages/VideoIntro2";
import LandingBriefSection from "../components/Home/Landing/LandingBrief";
import Interactive from "../components/Home/Landing/Interactive";
const Routers = () => {
  return (
    <AnimatePresence exitBeforeEnter>
      <Router>
        <Routes>
          <Route path="/" element={<Intro />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/featured-projects" element={<Work />} />
          <Route path="/dreamers" element={<TheDreamers />} />
          <Route path="/intro" element={<Interactive />} />
          <Route path="/landing-brief" element={<LandingBriefSection/>} />
          <Route path="/intro2" element={<VIdeoIntro2 />} />
          <Route path="/vtc" element={<VTC />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Router>
      </AnimatePresence>
  );
};

export default Routers;

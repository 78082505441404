import styled from "styled-components";

const HeaderText3 = ({ title, color, title1, title2 }) => {
  return (
    <Head color={color}>
      <h1 className="mobile">
        {title1}
        <br />
        {title2}
      </h1>
    </Head>
  );
};

const Head = styled.div`
  h1 {
    color: ${(props) => props.color || "black"};
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.96px;
    font-family: "MarkPro", sans-serif;

  }

  @media (max-width: 1200px) {
    /* For laptop-sized screens */
  }

  @media (max-width: 912px) {
    /* For tablet-sized screens */
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    h1 {
      font-size: 30px;
    }
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */

    .mobile {
      display: block;
      font-size: 30px;
      color: ${(props) => props.color || "black"};
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.96px;
      // text-transform: capitalize;
    }
  }
`;

export default HeaderText3;

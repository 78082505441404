import React, { useEffect,useState } from "react";
import { motion } from "framer-motion"; // Import useAnimation
import styled from "styled-components";

const Preloader = () => {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 3000);
  }, []);

  return showLoader ?(
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Intro>
        <video src="/videos/7i-light-streak.mp4" autoPlay muted></video>
      </Intro>
    </motion.div>
  ):(
    <motion.div
    initial={{ opacity: 0.5 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
<Intro2>
<img src="/img/7text.png" alt="7even"/>
</Intro2>
    </motion.div>

  );
};

const Intro = styled.div`
  background: linear-gradient(to right, #07194d, #0d2045, #020429);
  height: 100vh;
  /* background: linear-gradient(#174677, #000837); */
  /* height: 100vh; */
  video {
    width: 100vw;
    height: 100vh;
    /* width: 100%;
    height: auto; */
    object-fit: cover;
  }
  @media screen and (max-width: 1200px) {
    video {
      width: 100vw;
      position: absolute;
      top: 20%;
      height: auto;
    }
  }
`;

const Intro2 = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/bg7e.png);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  img{
    width:500px;
    height:auto;
  }

  @media (max-width: 1280px) {
    /* For laptop-sized screens */
    img{
    width:400px;
    height:auto;
  }
  }

  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh !important;
    
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 100vh;
   
  }

  @media (max-width: 912px) {
    /* For tablet-sized screens */
    height: 100vh;
 
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    height: 100vh;
  
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    height: 100vh;
    img{
    width:200px;
    height:auto;
  }
   
  }
`;
export default Preloader;

import styled from "styled-components";

const FooterP = ({ color }) => {
  return (
    <Para color={color}>
      <p>+234 817 853 7863</p>
      <p>info@7eveninteractive.com</p>
      <br />
      <p>6a Foluso Alade street, Awuse Estate,</p>
      <p>Opebi, Ikeja, Lagos, Nigeria</p>
   <div className="mt"></div>
    </Para>
  );
};

const Para = styled.div`
  p {
    color: ${(props) => props.color || "black"};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px; /* 150% */
    letter-spacing: -0.3px;
    font-family: "MavenRegular",Times;

  }
.mt{
  margin-bottom:30px;
}
  @media (max-width: 480px) {
    p{
      font-size: 16px;
    }
  }
`;

export default FooterP;

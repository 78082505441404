import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ScrollButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: ${props => (props.visible ? 'block' : 'none')};
  background-color: ${props => (props.atEnd ? '#fff' : '#00D7FF')};
  color: ${props => (props.atEnd ? '#000' : '#fff')};
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
    background: #030C2D;
  }
  
`;

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    setIsVisible(scrollTop > 300);
    setIsAtEnd(scrollTop >= windowHeight);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <ScrollButton
      visible={isVisible}
      atEnd={isAtEnd}
      onClick={scrollToTop}
    >
      ↑
    </ScrollButton>
  );
};

export default BackToTop;

import React, { useEffect } from "react";

import styled from "styled-components";
import { motion } from "framer-motion";
import WorkSection from "../components/Works/Works";
import WorkContent from "../components/Works/WorkContent";
import FooterSection from "../components/Home/Footer/Footer";
import BackToTop from "../components/BackToTop";

const Work = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Projects>
        <WorkSection/>
        <WorkContent/>
     <FooterSection title="your Dream belongs here" title1="your Dream" title2="belongs here"/>
     <BackToTop/>
      </Projects>
    </motion.div>
  );
};

const Projects = styled.div`
  font-family: "Maven Pro", sans-serif;
  .advert,
  h5 {
    text-align: center;
  }
  h5 {
    font-weight: 600;
  }

  h2 {
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  .info {
    padding-top: 7rem;
  }

  .work {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding: 2rem 0;

    div {
      width: 15rem;
    }

    img, video, iframe {
      width: 15rem;
      height: 15rem;
    }

    button {
      border-radius: 30px;
      background: #001033;
      border: 1px solid #000;
      color: #01eaff;
      font-size: 12px;
      padding: 0.4rem 0.8rem;
      transition: 0.3s;
    }
    button:hover {
      transform: scale(1.06);
    }
  }

  @media screen and (min-width: 1240px) {
    .work {
      padding: 5rem 0;
    }
    
  }

  @media screen and (min-width: 870px) {
    .work {
      padding: 3rem 0;

      div {
        width: 20rem;
      }
      img, video, iframe {
        width: 20rem;
        height: 20rem;
      }
    }

    h2 {
      font-size: 2rem;
    }
    button {
      font-size: 14px;
    }
  }

  @media screen and (min-width: 600px) {
    h2 {
      font-size: 1.3rem;
    }
    .info {
      padding-top: 7rem;
    }
  }
`;

export default Work;

import React from "react";
import styled from "styled-components";
import LandingSection from "../components/Home/Landing/Landing";
import WhoweareSection from "../components/Home/Whoweare/Whoweare"
import FooterSection from "../components/Home/Footer/Footer";
import AwardSection from "../components/Home/Awards/Awards";
import FeaturedSection from "../components/Home/Featured/Featured";
import DreamersSection from "../components/Home/Dreamers/Dreamers";
import PartnershipSection from "../components/Home/Partnerships/PartnershipSection";
import OurClientsSection from "../components/Home/Ourclient/OurClients";
import HowWeWorkSection from "../components/Home/HowWeWork/How";
import { motion } from "framer-motion";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackToTop from "../components/BackToTop";

const Home = () => {
  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ToastContainer theme="dark" />
      <HomeDiv>
        <LandingSection/>
        <WhoweareSection/>
        <HowWeWorkSection/>
        <OurClientsSection/>
        <FeaturedSection/>
        <PartnershipSection/>
        <DreamersSection/>
        <AwardSection/>
        <FooterSection title="Tell Us Your Dreams" title1="Tell us" title2="Your Dreams"/>
        <BackToTop/>
      </HomeDiv>
    </motion.div>
  );
};

const HomeDiv = styled.div`
  // min-height: 100vh;
  font-family: "Maven Pro", sans-serif;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media screen and (max-width: 420px) and (min-width: 280px) {
    p {
      font-size: 12px;
    }
  }
`;

export default Home;

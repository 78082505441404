import styled from "styled-components";
import Navbar from "../../Navbar/Navbar";

const LandingBriefSection = () => {

 
  return (
    <Land>
      <Navbar />
      <div className="container div">
        <div className="pdiv">
          <p className="text">
            We Use Data To Power Ideas,<br/> Enabled By Technology
          </p>
        </div>
      </div>
    </Land>
  );
};

const Land = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/bgfeatured.png);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .text {
    font-weight: 400;
    font-size: 45px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.015em;
    text-transform: capitalize;
    color: white;
    font-family: "MarkPro", sans-serif;
  }

  @media (max-width: 1280px) {
    /* For laptop-sized screens */
  }

  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh !important;
    .text {
        font-size: 26px;
        line-height: 40px;

      }
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 50vh;
    .text {
        font-size: 30px;
    line-height: 40px;

      }
  }

  @media (max-width: 912px) {
    /* For tablet-sized screens */
    height: 50vh;
    .text {
        font-size: 26px;
        line-height: 40px;

      }
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    height: 50vh;
    .text {
        font-size: 24px;
       line-height: 30px;

      }
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    height: 50vh;
    .pdiv {
      display: block;
      padding: 0px 40px;
    }
    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.015em;
      text-transform: capitalize;
      color: white;
    }
   
  }
`;

export default LandingBriefSection;

import styled from "styled-components";
import HeaderText from "../../Text/HeaderText";
import FeaturedButton from "./FeaturedButton";
import { NavLink } from "react-router-dom";

const FeaturedSection = () => {
  return (
    <Featured>
      <div className="container div">
        <HeaderText
          title="Featured Projects"
          title1="Featured"
          title2="Projects"
          color="#00D7FF"
        />
      </div>
      <FeaturedContainer>
        <div className="slider">
          <div>
            <IframeContainer>
            <iframe  src="https://www.youtube.com/embed/HVlLMQxCZ9I?si=xM6kN0bjj6JeB5v9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </IframeContainer>
          </div>
          <div>
            <IframeContainer>
            <iframe  src="https://www.youtube.com/embed/SEN1_j_tyuk?si=EDy8vYLIw5ba98C-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </IframeContainer>
          </div>
          <div>
            <IframeContainer>
            <iframe  src="https://www.youtube.com/embed/Jz9IdpBbQYo?si=SypRMNfa5Gv98mIE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </IframeContainer>
          </div>
        </div>
      </FeaturedContainer>
      <div className="bt">
      <NavLink
        to="/featured-projects"
        // target="_blank"
        // rel="noopener noreferrer"
        className="custom-link"
      >
        <div className="container div">
          <FeaturedButton />
        </div>
      </NavLink>
      </div>
    </Featured>
  );
};

const IframeContainer = styled.div`
  iframe {
    width: 450px; /* Set your desired width here */
    height: 370px; /* Set your desired height here */
  }
  @media (max-width: 1920px) {
    /* For tablet-sized screens */
    iframe {
      width: 580px; /* Set your desired width here */
      height: 340px; /* Set your desired height here */
    }
  }
  @media (max-width: 1600px) {
    /* For tablet-sized screens */
    iframe {
      width: 450px; /* Set your desired width here */
      height: 315px; /* Set your desired height here */
    }
  }
  @media (max-width: 1440px) {
    iframe {
      width: 420px; /* Set your desired width here */
      height: 315px; /* Set your desired height here */
    }
  }

  @media (max-width: 1280px) {
    /* For tablet-sized screens */
    iframe {
      width: 373px; /* Set your desired width here */
      height: 250px; /* Set your desired height here */
    }
  }

  @media (max-width: 1024px) and (max-height: 600px) {
    iframe {
      width: 400px; /* Set your desired width here */
      height: 250px; /* Set your desired height here */
    }
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    iframe {
      width: 285px; /* Set your desired width here */
      height: 200px; /* Set your desired height here */
    }
  }
  @media (max-width: 912px) {
    /* For tablet-sized screens */
    iframe {
      width: 210px; /* Set your desired width here */
      height: 160px; /* Set your desired height here */
    }
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    iframe {
      width: 300px; /* Set your desired width here */
      height: 200px; /* Set your desired height here */
    }
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    iframe {
      width: 280px;
      height: 120px;
    }
  }
`;
const Featured = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/bgfeatured.png);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;

  .custom-link {
    /* Remove underline from the anchor element */
    text-decoration: none;
    color: #030c2d;
    font-family: "MavenMedium",Times;

  }
  .bt{
    /* width:190px; */
    /* width: 10%; */
  }
  @media (max-width: 1200px) {
    /* For laptop-sized screens */
    height: 100vh;
  }
  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh;
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 50vh;
  }
  @media (max-width: 912px) {
    /* For tablet-sized screens */
    height: 50vh;
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    height: 50vh;
    .bt{
    width:190px;
  }
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    height: 100vh;
    .bt{
    width:140px;
  }
  }
`;
const FeaturedContainer = styled.div`
  .slider {
    display: flex;
    overflow: auto;
    white-space: nowrap;
    gap: 2rem;
    padding: 4rem 3rem;
  }

  @media (max-width: 1200px) {
    /* For laptop-sized screens */
  }

  @media (max-width: 912px) {
    /* For tablet-sized screens */
    .slider {
      display: flex;
      overflow: auto;
      white-space: nowrap;
      gap: 2rem;
      padding: 3rem 3rem;
    }
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    .slider {
      display: flex;
      overflow: auto;
      white-space: nowrap;
      gap: 2rem;
      padding: 1rem 3rem;
    }
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    .slider {
      display: flex;
      flex-direction: column;
      // overflow: auto;
      white-space: nowrap;
      gap: 2rem;
      padding: 1rem 3rem;
    }
  }
`;

export default FeaturedSection;

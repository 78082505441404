import styled from "styled-components";
import { Carousel } from "react-bootstrap";

const FifthSection = () => {
  return (
    <Fifth>
      <img src="/img/downloadtemp.png" className="temp" alt="template" />
      <div className="container div">
        <div className="rightdiv">
          <img src="/img/curved.png" className="curved" alt="curved" />
          <div className="wrap">
          <li className="ptext">
            We are sure you have something to say. Select and Download any of
            <br /> the templates, write your thoughts on it and share to the
            world.
          </li>
          <li className="ptext">
            Kindly include the Hashtags #VTC #ValueTheCraft <br />
            #ValueTheCraftsman as you share.
          </li>
          </div>
         
          <li className="mobile">
            We are sure you have something to say. Select and Download any of
            the templates, write your thoughts on it and share to the world.
          </li>
          <li className="mobile">
            Kindly include the Hashtags #VTC #ValueTheCraft #ValueTheCraftsman
            as you share.
          </li>
        </div>
        <div className="carousel">
          <Carousel variant="dark" controls={false}>
            <Carousel.Item>
              <a
                href="https://asset.cloudinary.com/wild-turkey/9da05fc3dad0eadb8b458bc4c5ca02d2"
                // onClick={e => download(e)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <div>
                  <img
                    src="/img/t11.png"
                    alt="carousel"
                    className="caroul"
                  />
                </div>
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <a
                href="https://asset.cloudinary.com/wild-turkey/de4c5696d1fd22abdf1a9f195a796e4c"
                // onClick={e => download(e)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <div>
                  <img
                    src="/img/t2.png"
                    alt="carousel"
                    className="caroul"
                  />
                </div>
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <a
                href="https://asset.cloudinary.com/wild-turkey/12e4bcad9706149547e4ae631a498958"
                // onClick={e => download(e)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <div>
                  <img
                    src="/img/t3.png"
                    alt="carousel"
                    className="caroul"
                  />
                </div>
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <a
                href="https://asset.cloudinary.com/wild-turkey/5c7dd520b3fc4744f7953a883d276a86"
                // onClick={e => download(e)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <div>
                  <img
                    src="/img/t4.png"
                    alt="carousel"
                    className="caroul"
                  />
                </div>
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <a
                href="https://asset.cloudinary.com/wild-turkey/cda50f4ca655e74759cf8738b98f5dc3"
                // onClick={e => download(e)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <div>
                  <img
                    src="/img/t5.png"
                    alt="carousel"
                    className="caroul"
                  />
                </div>
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <a
                href="https://asset.cloudinary.com/wild-turkey/bbe202caf75a6534d54948b1301e784f"
                // onClick={e => download(e)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <div>
                  <img
                    src="/img/t6.png"
                    alt="carousel"
                    className="caroul"
                  />
                </div>
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <a
                href="https://asset.cloudinary.com/wild-turkey/f4af91bcc5b2b46f15f266babfefc869"
                // onClick={e => download(e)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <div>
                  <img
                    src="/img/t7.png"
                    alt="carousel"
                    className="caroul"
                  />
                </div>
              </a>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </Fifth>
  );
};

const Fifth = styled.div`
  // background-color:#BABABA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  .rightdiv {
    width: 100%;
  }
.carousel{
  width: 100%; 
}
  .ptext {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.3px;
    color: #000723;
    list-style-type: none;
    margin-bottom: 20px;
  }
  .wrap{
    margin-top: 50px;
  }
  .mobile {
    display: none;
  }
  .temp {
    height: auto;
    width: 30rem;
    margin-bottom: 70px;
  }
  .caroul {
    height: auto;
    width: 30rem;
    /* align-self: flex-end; */
    position: relative;
    right:-150px;
  }
  .div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .curved {
    width: 40rem;
    height: auto;
    position: absolute;
    bottom: 3rem;
    left: 30rem;
    z-index: -1;
  }

  @media (max-width: 1280px) {
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    .temp {
      height: auto;
      width: 20rem;
      margin-bottom: 120px;
    }
    .rightdiv {
    width: 100%;
  }
.carousel{
  width: 100%; 
}
    .caroul {
     height: auto;
    width: 32rem;
    position: relative;
    right:-40px;
  }
  }

  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh !important;
    .curved {
      width: 38rem;
      height: auto;
      position: absolute;
      bottom: 3rem;
      left: 10rem;
      z-index: -1;
    }

    .caroul {
      height: auto;
      width: 25rem;
      position: relative;
      right:-63px;
    }

    .ptext {
      display: none;
    }

    .mobile {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.3px;
      color: #000723;
      list-style-type: none;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 50vh;
    .curved {
      width: 38rem;
      height: auto;
      position: absolute;
      bottom: 3rem;
      left: 10rem;
      z-index: -1;
    }

    .caroul {
      height: auto;
      width: 25rem;
      position: relative;
      right:-45px;
    }

    .ptext {
      display: none;
    }

    .mobile {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.3px;
      color: #000723;
      list-style-type: none;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 912px) {
    /* For tablet-sized screens */
    height: 50vh;
    .caroul {
      height: auto;
      width: 20rem;
      position: relative;
    right:-20px;
    }

    .ptext {
      display: none;
    }

    .mobile {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.3px;
      color: #000723;
      list-style-type: none;
      margin-bottom: 20px;
    }
    .curved {
      width: 27rem;
      height: auto;
      position: absolute;
      bottom: 5rem;
      left: 15rem;
      z-index: -1;
    }
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    height: 50vh;

    .caroul {
      height: auto;
      width: 15rem;
      position: relative;
      right:-10px;
    }
    .temp {
      height: auto;
      width: 15rem;
      margin-bottom: 20px;
    }
    .curved {
      width: 15rem;
      height: auto;
    }
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    height: 100vh;
    .ptext {
      display: none;
    }

    .mobile {
      display: block;
      font-size: 12px;
      line-height: 15px;
    }
    .div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }

    .caroul {
      height: auto;
    width: 95%;
    }

    .curved {
      display: none;
    }
    .temp {
      height: auto;
      width: 10rem;
      margin-bottom: 20px;
    }
    .carousel{
  width: 100%;
}
  }
`;

export default FifthSection;

import React, { useEffect } from "react";
import styled from "styled-components";
import DreamersSection from "../components/Dreamers/Dream";
import FooterSection from "../components/Home/Footer/Footer";
import DreamersContent from "../components/Dreamers/DreamersContent";
import { motion } from "framer-motion";
import BackToTop from "../components/BackToTop";


const TheDreamers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
    <Dreamers>
      <DreamersSection/>
      <DreamersContent/>
      <FooterSection title="let us make your dreams come through" title1="let us make your" title2="dreams come through"/>
<BackToTop/>
    </Dreamers>
    </motion.div>
  )
}

const Dreamers = styled.div`
    font-family: 'Maven Pro', sans-serif;
`

export default TheDreamers
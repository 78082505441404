import styled from "styled-components";

const FeaturedButton = () => {

  return (
    <Featured>
      <span>See more</span>
    </Featured>
  );
};

const Featured = styled.div`
margin-top:20px;
cursor:pointer;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border: 2px solid;
border-color: #01eaff;
border-radius: 41px;
box-shadow: 0px 4px 4px #00000040;
height: 50px;
width: 190px;
border: 2px solid #FFF;
background: #01EAFF;

span {
  color: #030C2D important!; /* Set text color to the desired color */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.24px;
  line-height: normal;
  text-align: center;
  margin: 0; /* Remove any default margin for better centering */
  font-family: "MavenMedium",Times;

}

  @media (max-width: 1200px) { /* For laptop-sized screens */
  
  }
  @media (max-width: 912px) { 

  }

  @media (max-width: 640px) { /* For tablet-sized screens */
  margin-top:0px;
  cursor:pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  border-color: #01eaff;
  border-radius: 41px;
  box-shadow: 0px 4px 4px #00000040;
  height: 50px;
  width: 190px;
  background-color: white; /* Set background color to white */
  
  span {
    color: #030C2D important!; /* Set text color to the desired color */
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -0.24px;
    line-height: normal;
    text-align: center;
    margin: 0; /* Remove any default margin for better centering */
  }
  }

  @media (max-width: 480px) { /* For mobile phone-sized screens */
  margin-top:0px;
  cursor:pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  border-color: #01eaff;
  border-radius: 41px;
  box-shadow: 0px 4px 4px #00000040;
  height: 40px;
  width: 140px;
  background-color: white; /* Set background color to white */
  
  span {
    color: #030C2D important!; /* Set text color to the desired color */
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.24px;
    line-height: normal;
    text-align: center;
    margin: 0; /* Remove any default margin for better centering */
  }
  }
`;


export default FeaturedButton;

import styled from "styled-components";
import NavbarVtc from "../Navbar/NavbarVtc";
import { Carousel } from "react-bootstrap";

const FirstSection = () => {

  return (
    <>
    
          <NavbarVtc
        one="join the movement"
        bgColor="#fff"
        // bgColor="#E5E5E5"
      />
        <div className="container div" >
        <Section>
        <Carousel variant="dark" controls={false}>
      
        <Carousel.Item>
            <div className="carouselItem">
              <img src="/img/sl11.png" alt="valuethecraft" />
              <img src="/img/sl12.png" alt="vtc" />
              <img src="/img/sl13.png" alt="valuethecraftman" />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carouselItem2">
              <img src="/img/sl21.png" alt="creatives" />
              <img src="/img/sl22.png" alt="valuethecraft" />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carouselItem2">
              <img src="/img/sl31.png" alt="creatives" />
              <img src="/img/sl32.png" alt="valuethecraftman" />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carouselItem2">
              <img src="/img/sl41.png" alt="creatives" />
              <img src="/img/sl42.png" alt="vtc" />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carouselItem3">
              <img src="/img/sl51.png" alt="joinmovement" />
            </div>
          </Carousel.Item>
          
        </Carousel>
      </Section>
      
        </div>
    </>

  );
};


const Section = styled.div`
height:90vh;
  .carouselItem {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    img {
      height: auto;
      width: 20rem;
    }
    img:nth-child(2) {
      width: 30rem;
    }
  }
  .carouselItem2 {
    height: 90vh!important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    img {
      height: auto;
      width: 70rem;
    }
    img:nth-child(2) {
      width: 20rem;
    }
  }
  .carouselItem3 {
    height: 90vh!important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    img {
      height: auto;
      width: 70rem;
    }

  }
  .carousel-dark .carousel-indicators [data-bs-target] {
    width: 10px; 
    height: 10px; 
    border-radius: 50%; 
    background-color: #FF9E9E; 
    // box-shadow: none; 
    // margin: 0 3px; 
  }

  

  
@media (max-width: 1280px) { /* For laptop-sized screens */
height: 90vh;
.carouselItem {
    height: 90vh!important;
    img {
      height: auto;
      width: 20rem;
    }
    img:nth-child(2) {
      width: 25rem;
    }
  }
  .carouselItem2 {
    height: 90vh;
  

    img {
      height: auto;
      width: 40rem;
    }
    img:nth-child(2) {
      width: 10rem;
    }
  }

}
@media (max-width: 1024px) and (max-height: 600px){
    .carouselItem {
        height: 90vh!important;
        img {
          width: 20rem;
          height:auto;
        }
        img:nth-child(2) {
          width: 10rem;
        }
      }
      .carouselItem3 {
        height: 90vh!important;
        img {
          height: auto;
          width: 50rem;
        }
    
      }
      .carouselItem2 {
        height: 90vh!important;
        img {
          width: 25rem;
        }
        img:nth-child(2) {
          width: 10rem;
        }
      }
     }

 @media (max-width: 1024px) and (min-height: 1366px){
    height: 50vh;
    .carouselItem3 {
        height: 50vh!important;
        img {
          height: auto;
          width: 50rem;
        }
    
      }
        .carouselItem {
        height: 50vh;
                img {
                  width: 15rem;
                }
                img:nth-child(2) {
                  width: 20rem;
                }
          }
        
        .carouselItem2 {
            height: 50vh;
            img {
              height: auto;
              width: 40rem;
            }
            img:nth-child(2) {
              width: 10rem;
            }
          }
        }
     
@media (max-width: 912px) { /* For tablet-sized screens */
height: 50vh!important;
.carouselItem {
    height: 50vh!important;

        img {
          width: 10rem;
        }
        img:nth-child(2) {
          width: 15rem;
        }
  }
  .carouselItem2 {
    height: 50vh!important;

    img {
      height: auto;
      width: 20rem;
    }
    img:nth-child(2) {
      width: 10rem;
    }
  }
  .carouselItem3 {
    height: 50vh!important;
    img {
      height: auto;
      width: 40rem;
    }

  }
}


@media (max-width: 640px) { /* For mobile phone-sized screens */
height: 50vh!important;
.carouselItem {
height: 50vh;
        img {
          width: 7rem;
        }
        img:nth-child(2) {
          width: 10rem;
        }
  }
  .carouselItem2 {
    height: 50vh;
    img {
      height: auto;
      width: 15rem;
    }
    img:nth-child(2) {
      width: 7rem;
    }
  }
  .carouselItem3 {
    height: 50vh!important;
    img {
      height: auto;
      width: 30rem;
    }

  }
}



@media (max-width: 480px) { /* For mobile phone-sized screens */
height: 50vh;
.carouselItem {
height: 50vh;
        img {
          width: 4rem;
        }
        img:nth-child(2) {
          width: 6rem;
        }
  }
  .carouselItem3 {
    height: 50vh!important;
    img {
      height: auto;
      width: 20rem;
    }

  }
  .carouselItem2 {
    height: 50vh;
    img {
      width: 10rem;
    }
    img:nth-child(2) {
      width: 6rem;
    }
}}
`




export default FirstSection;

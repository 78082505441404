import styled from "styled-components";

const HeaderText2 = ({ title, title2 }) => {
  return (
    <Head>
      <h1 className="h1white">{title}</h1>
      <h1 className="h1black">{title2}</h1>
    </Head>
  );
};

const Head = styled.div`
display:flex;
gap:1rem;
#spec{
    color:"black";
}
.h1white {
    color:white;
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.96px;
text-transform: capitalize;
font-family: "MarkPro", sans-serif;

  }
  .h1black{
    color:black;
font-size: 54px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.96px;
text-transform: capitalize;
font-family: "MarkPro", sans-serif;
  }


  @media (max-width: 1200px) { /* For laptop-sized screens */

}

@media (max-width: 912px) { /* For tablet-sized screens */
.h1white {
    color:white;
font-size: 30px;
  }

  .h1black{
color:black;
font-size: 30px;
  }
}

@media (max-width: 640px) { /* For mobile phone-sized screens */
.h1white {
    color:white;
font-size: 30px;
  }

  .h1black{
color:black;
font-size: 30px;
  }

  }
}

@media (max-width: 480px) { /* For mobile phone-sized screens */
.h1white {
    color:white;
font-size: 25px;
  }

  .h1black{
color:black;
font-size: 25px;
  }

`;

export default HeaderText2;

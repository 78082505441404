import React, { useEffect } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import AboutContent from "../components/About/AboutContent";
import AboutUsSection from "../components/About/AboutUs";
import FooterSection from "../components/Home/Footer/Footer";
import BackToTop from "../components/BackToTop";
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Section>
      <AboutUsSection/>
      <AboutContent/>
      <FooterSection title="Tell Us Your Dreams" title1="Tell us" title2="Your Dreams"/>
      <BackToTop/>
      </Section>

    </motion.div>
  );
};

const Section = styled.div`
  font-family: 'Maven Pro', sans-serif;
// height:100vh;
`;

export default About;

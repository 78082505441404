import styled from "styled-components";
import Navbar from "../components/Navbar/Navbar";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";

const VIdeoIntro2 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/landing-brief");
    }, 20500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  return (
    <Section>
      <div className="nav">
        <Navbar />
      </div>
      {isDesktopOrLaptop && (
        <div className="video">
          <video src="/videos/introweb.mp4" autoPlay loop muted></video>
        </div>
      )}
      {isPortrait && (
        <div className="video">
          <video src="/videos/intromobile.mp4" autoPlay loop muted></video>
        </div>
      )}

      <Container>
        <Link to="/landing-brief">
          <div className="skip">
            <p>Skip</p>
            <AiOutlineArrowRight size={25} />
          </div>
        </Link>
      </Container>
    </Section>
  );
};

const Section = styled.div`
  position: relative;
  font-family: "Maven Pro", sans-serif;
  background: linear-gradient(to right, #07194d, #0d2045, #020429);
  min-height: 100vh;

  .video {
    display: grid;
    place-content: center;
  }
  video {
    width: 80vw;
  }
  .skip {
    position: absolute;
    bottom: 50px;
    right: 13%;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    cursor: pointer;
  }
  .skip:nth-child(2) {
    margin-top: 10px;
  }
  a {
    text-decoration: none;
  }
  .nav {
    margin-bottom: 1.5rem;
  }
`;

export default VIdeoIntro2;

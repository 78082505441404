import React, { useEffect } from "react";
import styled from "styled-components";
import FirstSection from "../components/Vtc/FirstSection";
import SecondSection from "../components/Vtc/SecondSection";
import ThirdSection from "../components/Vtc/ThirdSection";
import FourthSection from "../components/Vtc/FourthSection";
import FifthSection from "../components/Vtc/FifthSection";
import SixthSection from "../components/Vtc/SixthSection";
import SeventhSection from "../components/Vtc/SeventhSection";
import BackToTop from "../components/BackToTop";

const VTC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Page>
        <FirstSection/>
        <SecondSection/>
        <ThirdSection/>
        <FourthSection/>
       <FifthSection/>
       <SixthSection/>
       <SeventhSection/>
        <div className="bottom">
          <p>Powered by 7even interactive</p>
        </div>
        <BackToTop/>
      </Page>
    </div>
  );
};

const Page = styled.div`
.bottom {
  height: 3rem;
  background-color:#FFFF99;
  display: flex;
  justify-content: center;
  align-items:center;
  width:100%;

  p {
    
    text-align: center;
    font-size: 20px;
    line-height: 38px;
    font-weight: 500;
    color: #001033;
    margin:auto;
    font-family: "MavenMedium", Times;

  }
}

@media (max-width: 480px) {
  p {
    text-align: center;
    font-size: 16px!important;
    line-height: 38px;
    color: #001033;
    margin:auto;
  }
}

`;

export default VTC;

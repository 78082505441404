import styled from "styled-components";
import { Carousel } from "react-bootstrap";

const FourthSection = () => {

  return (
        <Fourth>
        <div className="container div" >
    <div className="rightdiv">
<img src="/img/sec4img.png" className="imgsec4" alt="container"/>
    </div>
    <div className="carousel">
    <Carousel variant="dark" controls={false}>
            <Carousel.Item>
                
            <a href="https://asset.cloudinary.com/wild-turkey/4a12ab68cbeed63fc083cad708449343" 
            // onClick={e => download(e)}
            rel="noopener noreferrer"
            target="_blank">
              <div>
                <img src="/img/s1.png" alt="carousel" className="caroul"/>
          
              </div>
              </a>
            </Carousel.Item>
            <Carousel.Item>
            <a href="https://asset.cloudinary.com/wild-turkey/df9442028d7f457bf97d44599dc6148a" 
            // onClick={e => download(e)}
            rel="noopener noreferrer"
            target="_blank">
              <div>
                <img src="/img/s2.png" alt="carousel" className="caroul"/>
         
              </div>
              </a>
            </Carousel.Item>

            <Carousel.Item>
            <a href="https://asset.cloudinary.com/wild-turkey/aba78d10d98199c20d7f6d8d86941825" 
            // onClick={e => download(e)}
            rel="noopener noreferrer"
            target="_blank">
              <div>
                <img src="/img/s3.png" alt="carousel" className="caroul"/>
         
              </div>
              </a>
            </Carousel.Item>



            <Carousel.Item>
            <a href="https://asset.cloudinary.com/wild-turkey/c51cd1654f4c9c4564982e14c491e070" 
            // onClick={e => download(e)}
            rel="noopener noreferrer"
            target="_blank">
              <div>
                <img src="/img/s4.png" alt="carousel" className="caroul"/>
         
              </div>
              </a>
            </Carousel.Item>


            <Carousel.Item>
            <a href="https://asset.cloudinary.com/wild-turkey/bf7ef61f6e88ee2a5976fec3272cfd4a" 
            // onClick={e => download(e)}
            rel="noopener noreferrer"
            target="_blank">
              <div>
                <img src="/img/s5.png" alt="carousel" className="caroul"/>
         
              </div>
              </a>
            </Carousel.Item>
            <Carousel.Item>
            <a href="https://asset.cloudinary.com/wild-turkey/cf6d9ee0ce114f840355efdebb562a6a" 
            // onClick={e => download(e)}
            rel="noopener noreferrer"
            target="_blank">
              <div>
                <img src="/img/s6.png" alt="carousel" className="caroul"/>
         
              </div>
              </a>
            </Carousel.Item>
            <Carousel.Item>
            <a href="https://asset.cloudinary.com/wild-turkey/8aa09ff240ee6a2d1b8076433c6de415" 
            // onClick={e => download(e)}
            rel="noopener noreferrer"
            target="_blank">
              <div>
                <img src="/img/s7.png" alt="carousel" className="caroul"/>
         
              </div>
              </a>
            </Carousel.Item>
          </Carousel>
    </div>
        </div>
    </Fourth>

  );
};

const Fourth = styled.div`
background-color:#00D7FF;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
height:100vh;

.carousel{
  width:100%;
}
.caroul{
    height: auto;
    width: 32rem;
    // z-index:12;
}
.div{
    display:flex;
    justify-content:center;
    align-items:center;
}

  .imgsec4{
    width:45rem;
    height:auto;
  }



  @media (max-width: 1400px) { /* For laptop-sized screens */

  .carousel{
    width:100%;
  }
  
  .caroul{
      height: auto;
      width: 32rem;
  }
  .div{
      display:flex;
      justify-content:center;
      align-items:center;
  }
  
    .imgsec4{
      width:60rem;
      height:auto;
    }
  }





@media (max-width: 1024px) and (max-height: 600px){
    height: 100vh!important;
    .caroul{
        height: auto;
        width: 25rem;
        
    }
    
    .imgsec4{
        width:100%;
        height:auto;
      }
     }

     @media (max-width: 1024px) and (min-height: 1366px){
height:50vh;
.caroul{
    height: auto;
    width: 25rem;
    
}

.imgsec4{
    width:100%;
    height:auto;
  }
     }
  


@media (max-width: 912px) { /* For tablet-sized screens */
height:50vh;
.caroul{
    height: auto;
    width: 20rem;

    
}

.imgsec4{
    width:100%;
    height:auto;
  }
}

@media (max-width: 640px) { /* For mobile phone-sized screens */
height: 50vh;
.carousel{
  width: 100%;
}
.righthdiv{
  width:100%;
}
  .caroul{
    /* height: auto; */
    height: auto;
    width: 15rem;
    
}

.imgsec4{
    width: 17rem;
    height: auto;
  }

}

@media (max-width: 480px) { /* For mobile phone-sized screens */
height: 100vh;
.div{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    gap:1rem;
}
.carousel{
  width: 100%;
}
.righthdiv{
  width:100%;
}
.caroul{
    /* height: auto; */
    height: auto;
    width: 100%;
    
}

.imgsec4{
    width: 100%;
    height: auto;
  }
}
`;




export default FourthSection;

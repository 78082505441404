import React, { useState } from "react"; 
import styled from "styled-components";
import HeaderText from "../../Text/HeaderText";

const HowWeWorkSection = () => {
  const [hoveredImage1, setHoveredImage1] = useState(false);
  const [hoveredImage2, setHoveredImage2] = useState(false);
  const [hoveredImage3, setHoveredImage3] = useState(false);

  const handleHoverImage1 = (isHovered) => {
    setHoveredImage1(isHovered);
  };

  const handleHoverImage2 = (isHovered) => {
    setHoveredImage2(isHovered);
  };

  const handleHoverImage3 = (isHovered) => {
    setHoveredImage3(isHovered);
  };

  return (
    <How>
      <div className="container div">
      <HeaderText
          title="How We Work "
          title1="How"
          title2="We Work"
          color="#01EAFF"
        />
      </div>
   
    <div  
    className={`container ${
      hoveredImage1 ? "imageseffect1" : "images"
    } ${hoveredImage2 ? "imageseffect2" : "images"} ${
      hoveredImage3 ? "imageseffect3" : "images"
    }`}
    
    >
          <img 
           onMouseEnter={() => handleHoverImage1(true)}
            onMouseLeave={() => handleHoverImage1(false)}
          src="/img/how1.png" alt="icon1" className="how1"
          
          />
          <img
           onMouseEnter={() => handleHoverImage1(true)}
           onMouseLeave={() => handleHoverImage1(false)}
          src="/img/how2.png" alt="icon2" className="how2" />
          <img 
             onMouseEnter={() => handleHoverImage2(true)}
             onMouseLeave={() => handleHoverImage2(false)}
          src="/img/how3.png" alt="icon3" className="how3"/>
          <img
            onMouseEnter={() => handleHoverImage2(true)}
            onMouseLeave={() => handleHoverImage2(false)}
          src="/img/how4.png" alt="icon4" className="how4"/>
          <img 
            onMouseEnter={() => handleHoverImage3(true)}
            onMouseLeave={() => handleHoverImage3(false)}
          src="/img/how5.png" alt="icon5" className="how5"/>
          <img 
            onMouseEnter={() => handleHoverImage3(true)}
            onMouseLeave={() => handleHoverImage3(false)}
          src="/img/how6.png" alt="icon6" className="how6"/>
        </div>
          <div 
            className={`${
              hoveredImage1 ? "texteffect1" : "text"
            } ${hoveredImage2 ? "texteffect2" : "text"} ${
              hoveredImage3 ? "texteffect3" : "text"
            }`}
          
          >
          <img
             onMouseEnter={() => handleHoverImage1(true)}
             onMouseLeave={() => handleHoverImage1(false)}
            className="text-image text1"src="/img/h1.png" alt="icon6" />
          <img
           onMouseEnter={() => handleHoverImage2(true)}
           onMouseLeave={() => handleHoverImage2(false)}
          className="text-image text2" src="/img/h2.png" alt="icon6"/>
          <img 
           onMouseEnter={() => handleHoverImage3(true)}
           onMouseLeave={() => handleHoverImage3(false)}
          className="text-image text3"src="/img/h3.png" alt="icon6"/>
                    <div className="mt-5">
          <li className="l1">Learn The Dream</li>
          <li className="l2"> Turn The Dream Into A Plan</li>
            <li className="l3">Make The Dream Alive</li>
          </div>
          </div>

    </How>
  );
};

const How = styled.div`
  height: 100vh;
  cursor:pointer;
  background-image: url(${process.env.PUBLIC_URL}/img/bgfeatured.png);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  /* justify-content:center; */
  align-items:center;

  .div {
    margin-top: 180px;
  }
li{
  display:none;
}
  .text{
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:1rem;
    // margin-top:3%;
    .text-image {
      width: auto;
      height: 40%;
      gap:2rem;
    transition: opacity 2s ease;

    }
    .text1,.text2,.text3{
      opacity:0;
    }
  }

  .texteffect1{
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:1rem;
    // margin-top:3%;
    .text-image {
      width: auto;
      height: 40%;
      gap:2rem;
    }
    .text1{
      opacity:1;

    }
    .text2,.text3{
      opacity:0.1;
    }
  }
  
  .texteffect2{
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:1rem;
    // margin-top:3%;
    .text-image {
      width: auto;
      height: 40%;
      gap:2rem;
    }
    .text2{
      opacity:1;

    }
    .text1,.text3{
      opacity:0.1;
    }
  }
  .texteffect3{
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:1rem;
    // margin-top:3%;
    .text-image {
      width: auto;
      height: 40%;
      gap:2rem;
    }
    .text3{
      opacity:1;

    }
    .text1,.text2{
      opacity:0.1;
    }
  }
.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 180px;
  gap: 4rem;
  transition: opacity 2s easeout;


  img {
    width: auto;
    height: 120px;
    transition: opacity 2s ease;
  }
  
}
  .imageseffect1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 180px;
    gap: 4rem;

    img {
      width: auto;
      height: 120px;
      
    }
    .how1,.how2.text1{
      opacity:1;
    }
     .how3,.how4,.how5,.how6{
      opacity:0.1;
    }

  }

  .imageseffect2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 180px;
    gap: 4rem;

    img {
      width: auto;
      height: 120px;
    }
    .how2,.how3,.how4{
      opacity:1;
    }
     .how1,.how5,.how6{
      opacity:0.1;
    }
  }  

  .imageseffect3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 180px;
    gap: 4rem;

    img {
      width: auto;
      height: 120px;
    }
    .how4,.how5,.how6{
      opacity:1;
    }
     .how1,.how3,.how2{
      opacity:0.1;
    }
  }  
@media(max-width:1600px){
  height: 100vh;
  cursor:pointer;
  background-image: url(${process.env.PUBLIC_URL}/img/bgfeatured.png);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  // justify-content:center;
  align-items:center;
  .div {
    margin-top: 80px;
  }
li{
  display:none;
}
  .text{
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:1rem;
    // margin-top:3%;
    .text-image {
      width: auto;
      height: 40%;
      gap:2rem;
    transition: opacity 2s ease;

    }
    .text1,.text2,.text3{
      opacity:0;
    }
  }

  .texteffect1{
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:1rem;
    // margin-top:3%;
    .text-image {
      width: auto;
      height: 40%;
      gap:2rem;
    }
    .text1{
      opacity:1;

    }
    .text2,.text3{
      opacity:0.1;
    }
  }
  
  .texteffect2{
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:1rem;
    // margin-top:3%;
    .text-image {
      width: auto;
      height: 40%;
      gap:2rem;
    }
    .text2{
      opacity:1;

    }
    .text1,.text3{
      opacity:0.1;
    }
  }
  .texteffect3{
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:1rem;
    // margin-top:3%;
    .text-image {
      width: auto;
      height: 40%;
      gap:2rem;
    }
    .text3{
      opacity:1;

    }
    .text1,.text2{
      opacity:0.1;
    }
  }
.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 180px;
  gap: 4rem;
  transition: opacity 2s easeout;


  img {
    width: auto;
    height: 120px;
    transition: opacity 2s ease;
  }
  
}
  .imageseffect1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 180px;
    gap: 4rem;

    img {
      width: auto;
      height: 120px;
      
    }
    .how1,.how2.text1{
      opacity:1;
    }
     .how3,.how4,.how5,.how6{
      opacity:0.1;
    }

  }

  .imageseffect2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 180px;
    gap: 4rem;

    img {
      width: auto;
      height: 120px;
    }
    .how2,.how3,.how4{
      opacity:1;
    }
     .how1,.how5,.how6{
      opacity:0.1;
    }
  }  

  .imageseffect3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 180px;
    gap: 4rem;

    img {
      width: auto;
      height: 120px;
    }
    .how4,.how5,.how6{
      opacity:1;
    }
     .how1,.how3,.how2{
      opacity:0.1;
    }
  }  

  @supports (-moz-appearance: none) {
    /* Firefox-specific styles here */
    .text{
    display:flex;
    justify-content:center;
    align-items:center;
    gap:1rem;
    margin-left: 20px;

    // margin-top:3%;
    .text-image {
      width: auto;
      height: 60px;
      margin-top: 30px;
      /* gap:2rem; */
    transition: opacity 2s ease;

    }
    .text1,.text2,.text3{
      opacity:0;
    }
  }
    .texteffect1{
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:1rem;
    // margin-top:3%;
    .text-image {
      width: auto;
      height: 60px;
      margin-top: 30px;
      width: auto;
      /* height: 40%; */
      /* gap:2rem; */
    }
    
    .text1{
      opacity:1;

    }
    .text2,.text3{
      opacity:0.1;
    }
  }
  
  .texteffect2{
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:1rem;
    // margin-top:3%;
    .text-image {
      width: auto;
      height: 60px;
      margin-top: 30px;
      /* width: auto;
      height: 40%;
      gap:2rem; */
    }
    .text2{
      opacity:1;

    }
    .text1,.text3{
      opacity:0.1;
    }
  }
  .texteffect3{
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:1rem;
    // margin-top:3%;
    .text-image {
      width: auto;
      height: 60px;
      margin-top: 30px;
      /* width: auto;
      height: 40%; */
      /* gap:2rem; */
    }
    .text3{
      opacity:1;

    }
    .text1,.text2{
      opacity:0.1;
    }
  }
}
}
  @media (max-width: 1280px) {
    /* For laptop-sized screens */
    .div {
      margin-top: 80px;
    }
    .images {
      margin-top: 150px;

      img {
        width: auto;
      }
    }
    
  }

  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh;
    .images {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 100px;
      gap: 2rem;
      img {
        width: auto;
        height: 100px;
      }
    }
    
  .text{
    .text-image {
      width: auto;
      height: 30%;
    }
  }
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 50vh;
    .images {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 100px;
      gap: 2rem;
      img {
        width: auto;
        height: 100px;
      }
    }
    .text{
      .text-image {
        width: auto;
        height:28%;
      }
    }
  }

  @media (max-width: 912px) {
    /* For tablet-sized screens */
    height: 50vh;
    .div {
      margin-top: 150px;
    }

    .images {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 100px;
      gap: 2rem;
      img {
        width: auto;
        height: 70px;
      }
    }
    .text{
      .text-image {
        width: auto;
        height:22%;
      }
    }
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    height: 50vh;
    .div {
      margin-top: 10px;
    }
    .images {
      gap: 1.5rem;

      img {
        width: auto;
        height: 50px;
      }
    }
    .text{
    margin-top:-7%;

      .text-image {
        width: auto;
        height:15%;
      }
    }
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0rem 3rem;
    .images {
      margin-top: 80px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 3rem;
      img {
        width: auto;
        height: 80px;
      }
    }
    .div {
      margin-top: 0px;
    }
    
    .text-image {
     display:none;
    }
    .texteffect1{
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:1rem;
    // margin-top:3%;
    .text-image {
      width: auto;
      height: 40%;
      gap:2rem;
    }
    .l1{
      opacity:1;
    }
    .l2,.l3{
      opacity:0.1;
    }
  }
  .texteffect2{
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:1rem;
    // margin-top:3%;
    .text-image {
      width: auto;
      height: 40%;
      gap:2rem;
    }
    .l2{
      opacity:1;
    }
    .l1,.l3{
      opacity:0.1;
    }
  }
  .texteffect3{
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:1rem;
    // margin-top:3%;
    .text-image {
      width: auto;
      height: 40%;
      gap:2rem;
    }
    .l3{
      opacity:1;
    }
    .l2,.l1{
      opacity:0.1;
    }
  }
    li {
      // margin-top:20px;
      display:block;
      font-weight: 400;
      font-size: 15px;
      line-height: 36px;
      letter-spacing: -0.3px;
      color: #FFFFFF;
      list-style-type: none;
      opacity:0;
    }
  }
`;

export default HowWeWorkSection;

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";
import Preloader from "../components/Preloader";

const Intro = () => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 5000);
  }, []);

  return showLoader ? (
    <Preloader />
  ) : (
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
    <HomePage>
      <div className="logo">
        <img src="/img/7even.png" alt="7even-logo" />
      </div>
      <div className="content">
        <p>
          You have found the Dream Makers
        </p>
          <div className="button-div">
        <Link to="/intro">
            <button>
              step right in
            </button>
        </Link>
          </div>
      </div>
    </HomePage>
    </motion.div>
  );
};

const HomePage = styled.div`
  font-family: 'Maven Pro', sans-serif;
  display: grid;
  place-content: center;
  grid-gap: 2rem;
  background: url("/img/bgintro.png") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: #fff;
  margin: 0 auto;
  height: 100vh;

  .logo {
    display: flex;
    justify-content: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  p {
    text-align: center;
    font-size: 1.2rem;
    color: #fff;
  }

  img {
    width: auto;
    height:90px;
  }
  .button-div {
    display: flex;
    justify-content: center;
  }
  button {
    text-transform: capitalize;
    color: #01EAFF;
    background: transparent;
    border: 1px solid #01EAFF;
    width: 190px;
    height: 50px;
    border-radius: 41px;
    cursor: pointer;
    transition: 0.4s;
    font-weight: 500;
  }

  button:hover {
    transform: scale(1.05);
  }

  @media screen and (max-width: 600px) {
    button {
      width: 150px;
      height: 40px;
    }
    p{
      font-size: 14px;
    }
    img {
      width: auto;
      height:70px;
    }
  }
`;

export default Intro;

import styled from "styled-components";
import Button from "./Button";

const SixthSection = () => {
  return (
    <Sixth>
      <img src="/img/merchandise.png" className="merch" alt="merchandize" />
      <div className="container div">
        <div className="dreamers-grid">
          <div className="grid-item img1">
            <img
              src="/img/tblack1.png"
              className="big"
              alt="blackshirt"
            />
          </div>
          <div className="grid-item img2">
            <img
              src="/img/twhite1.png"
              className="big"
              alt="whiteshirt"
            />
          </div>
          <div className="grid-item img3">
            <img
              src="/img/tblack2.png"
              className="medium"
              alt="blackshirt"
            />
          </div>
          <div className="grid-item img4">
            <img
              src="/img/twhite2.png"
              className="medium"
              alt="whiteshirt"
            />
          </div>
          <div className="grid-item img5">
            <img src="/img/badge.png" className="big" alt="badge" />
          </div>
          <div className="grid-item img6">
            <img src="/img/bag.png" className="big" alt="bag" />
          </div>
          <div className="grid-item img7">
            <div>
              <img src="/img/v1.png" className="small" alt="sticker1" />
            </div>
            <div>
              <img src="/img/v2.png" className="small" alt="sticker2" />
            </div>
          </div>
          <div className="grid-item img8">
            <img src="/img/vlarge.png" className="big" alt="sticker3" />
            <div className="fl">
              <Button title="pre-order"/>
            </div>
          </div>
        </div>
      </div>
    </Sixth>
  );
};

const Sixth = styled.div`
background-color:#FFFF99;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
height:100vh;
.dreamers-grid {
    columns: 4 auto;
    gap: 15px;
  }
.fl{
    align-self:center;
}
.merch{
    width:13rem!important;
    margin-bottom:20px!important;
}

.img7{
    display:flex;
    gap:1rem;
}

.img8{
    display:flex;
    flex-direction:column;
    justify-content:center;
    // align-items:center;
    // gap:0rem;
    // width:100%;
}

.big{
    width:300px;
    height:280px;
    margin-bottom:20px;
}
.medium{
    width:300px;
    height:280px;
    margin-bottom:20px; 
}

.small{
    width:140px;
    height:140px;
    margin-bottom:20px;

}
  @media (max-width: 1280px) { /* For laptop-sized screens */
  .dreamers-grid {
    columns: 4 auto;
    gap: 15px;
  }

.merch{
    width:15rem!important;
    margin-bottom:30px!important;
}

.img7{
    display:flex;
    gap:1rem;
}

.img8{
    display:flex;
    flex-direction:column;
    justify-content:center;
    // align-items:center;
    // gap:0rem;
    // width:100%;
}

.big{
    width:260px;
    height:250px;
    margin-bottom:20px;
}
.medium{
    width:260px;
    height:250px;
    margin-bottom:20px; 
}

.small{
    width:120px;
    height:140px;
    margin-bottom:20px;

}
  }





@media (max-width: 1024px) and (max-height: 600px){
    height: 100vh!important;
  
    
    .merch{
        width:10rem!important;
        margin-bottom:30px!important;
    }
    
    
    
    .img8{
        display:flex;
        flex-direction:column;
        justify-content:center;
    }
    
    .big{
        width:220px;
        height:200px;
        margin-bottom:20px;
    }
    .medium{
        width:220px;
        height:200px;
        margin-bottom:20px; 
    }
    
    .small{
        width:100px;
        height:100px;
        margin-bottom:20px;
    
    }
     }

     @media (max-width: 1024px) and (min-height: 1366px){
height:50vh;
 
.merch{
    width:10rem!important;
    margin-bottom:30px!important;
}

.big{
    width:200px;
    height:200px;
    margin-bottom:20px;
}
.medium{
    width:200px;
    height:200px;
    margin-bottom:20px; 
}

.small{
    width:90px;
    height:80px;
    margin-bottom:20px;

}

     }
  


@media (max-width: 912px) { /* For tablet-sized screens */
height:50vh;

 
.merch{
    width:12rem!important;
    margin-bottom:30px!important;
}

.big{
    width:160px;
    height:150px;
    margin-bottom:20px;
}
.medium{
    width:160px;
    height:150px;
    margin-bottom:20px; 
}

.small{
    width:90%;
    height:80px;
    margin-bottom:20px;

}

}

@media (max-width: 640px) { /* For mobile phone-sized screens */
height: 100vh;

.dreamers-grid {
    columns: 3 auto;
    gap: 10px;
  }
.merch{
    width:10rem!important;
    margin-bottom:30px!important;
}

.img8{
    display:flex;
    flex-direction:column;
    justify-content:center;
}

.big{
    width:170px;
    height:160px;
    margin-bottom:10px;
}

.medium{
    width:170px;
    height:160px;
    margin-bottom:10px; 
}

.small{
    width:75px;
    height:80px;
    margin-bottom:10px;

}

}

@media (max-width: 480px) { /* For mobile phone-sized screens */
height: 100vh;
.dreamers-grid {
    columns: 2 auto;
    gap: 5px;
  }
 
  .merch{
    width:8rem!important;
    margin-bottom:10px!important;
}

.big{
    width:100%;
    height:9rem;

    margin-bottom:10px;
}
.medium{
    width:100%;
    height:8rem;
    margin-bottom:10px; 
}

.small{
    width:100%;
    height:80px;
    margin-bottom:10px;

}}
`

export default SixthSection;

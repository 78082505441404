import styled from "styled-components";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Styles>
      <div className="center">
        <h1>404</h1>
        <p>Whoops, this page doesn't exist</p>
        <Link to="/home">
          <button>Go home</button>
        </Link>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  font-family: "Maven Pro";
  background-color: #081a48;
  color: #fff;
  min-height: 100vh;
  display: grid;
  place-content: center;
  
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
      font-weight: 600;
      font-size: 5rem;
  }
  p {
      font-weight: 500;
      font-size: 1.5rem;
  }
  button {
      font-weight: 500;
      border-radius: 1rem;
      border: none;
      padding: 0.3rem 0.6rem;
      transition: 0.4s;
  }
  button:hover {
      transform: scale(1.1);
  }
`;

export default NotFound;

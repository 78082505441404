import styled from "styled-components";
import HeaderText from "../Text/HeaderText";
import Navbar from "../Navbar/Navbar";

const DreamersSection = () => {
  return (
    <Dreamers>
      <Navbar />

      <div className="container div">
        <div>
          <HeaderText
            title="The Dreamers"
            title1="The Dreamers"
            color="white"
          />
        </div>
        <div className="imgbox">
          <ImageIcon src="/img/dreamers.png" alt="dreamers"></ImageIcon>
        </div>
      </div>
    </Dreamers>
  );
};

const ImageIcon = styled.img`
  width: auto;
  height: 300px;
  margin-right: 25px;

  @media (max-width: 1280px) {
    /* For tablet-sized screens */
    width: auto;
    height: 250px;
  }

  @media (max-width: 912px) {
    /* For tablet-sized screens */
    width: auto;
    height: 180px;
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    width: auto;
    height: 150px;
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    width: auto;
    height: 170px;
  }
`;

const Dreamers = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/bgabout.png);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .imgbox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0rem;
  }

  @media (max-width: 1280px) {
    /* For laptop-sized screens */
    height: 100vh;
    .div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .imgbox {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0rem;
    }
  }
  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh;
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 50vh;
  }
  @media (max-width: 912px) {
    /* For tablet-sized screens */
    height: 50vh;
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    height: 50vh;
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    height: 100vh;

    .div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .imgbox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
    }
  }
`;

export default DreamersSection;

import React, { useState } from "react";
import styled from "styled-components";
import Navbar from "../../Navbar/Navbar";
import "./style.css";
import { Link } from "react-router-dom";


const Interactive = () => {
  const [hoveredImage1, setHoveredImage1] = useState(false);
  const [hoveredImage2, setHoveredImage2] = useState(false);
  const [hoveredImage3, setHoveredImage3] = useState(false);
  const [hoveredImage4, setHoveredImage4] = useState(false);
  const [hoveredImage5, setHoveredImage5] = useState(false);
  const [hoveredImage6, setHoveredImage6] = useState(false);
  const [hoveredImage7, setHoveredImage7] = useState(false);
  //Mobile
  const [hoveredImage1m, setHoveredImage1m] = useState(false);
  const [hoveredImage2m, setHoveredImage2m] = useState(false);
  const [hoveredImage3m, setHoveredImage3m] = useState(false);
  const [hoveredImage4m, setHoveredImage4m] = useState(false);
  const [hoveredImage5m, setHoveredImage5m] = useState(false);
  const [hoveredImage6m, setHoveredImage6m] = useState(false);
  const [hoveredImage7m, setHoveredImage7m] = useState(false);

  const handleHoverImage1 = (isHovered) => {
    setHoveredImage1(isHovered);
  };
  const handleHoverImage2 = (isHovered) => {
    setHoveredImage2(isHovered);
  };
  const handleHoverImage3 = (isHovered) => {
    setHoveredImage3(isHovered);
  };
  const handleHoverImage4 = (isHovered) => {
    setHoveredImage4(isHovered);
  };
  const handleHoverImage5 = (isHovered) => {
    setHoveredImage5(isHovered);
  };
  const handleHoverImage6 = (isHovered) => {
    setHoveredImage6(isHovered);
  };
  const handleHoverImage7 = (isHovered) => {
    setHoveredImage7(isHovered);
  };

  //Mobile
  const handleHoverImage1m = (isHovered) => {
    setHoveredImage1m(isHovered);
  };
  const handleHoverImage2m = (isHovered) => {
    setHoveredImage2m(isHovered);
  };
  const handleHoverImage3m = (isHovered) => {
    setHoveredImage3m(isHovered);
  };
  const handleHoverImage4m = (isHovered) => {
    setHoveredImage4m(isHovered);
  };
  const handleHoverImage5m = (isHovered) => {
    setHoveredImage5m(isHovered);
  };
  const handleHoverImage6m = (isHovered) => {
    setHoveredImage6m(isHovered);
  };
  const handleHoverImage7m = (isHovered) => {
    setHoveredImage7m(isHovered);
  };
  return (
    <Land>
      <div className="container">
        <Navbar />
        <div className="element-home-our-story container">
          <div
            className={`${
              hoveredImage1 ? "imagehover1" : "element-i-home-our-story"
            } ${hoveredImage2 ? "imagehover2" : "element-i-home-our-story"}
             ${hoveredImage2 ? "imagehover2" : "element-i-home-our-story"}
            ${hoveredImage3 ? "imagehover3" : "element-i-home-our-story"}
            ${hoveredImage4 ? "imagehover4" : "element-i-home-our-story"}
            ${hoveredImage5 ? "imagehover5" : "element-i-home-our-story"}
            ${hoveredImage6 ? "imagehover6" : "element-i-home-our-story"}
            ${hoveredImage7 ? "imagehover7" : "element-i-home-our-story"}
            `}
          >
            <div className="overlap-group">
              <div className="overlap">
                <div className="div">
                  <img
                    src="img/hover1.png"
                    className="hover1"
                    alt="hovericon1"
                  />
                  <img
                    src="img/hover2.png"
                    className="hover2"
                    alt="hovericon2"
                  />
                  <img
                    src="img/hover3.png"
                    className="hover3"
                    alt="hovericon3"
                  />
                  <img
                    src="img/hover4.png"
                    className="hover4"
                    alt="hovericon4"
                  />
                  <img
                    src="img/hover5.png"
                    className="hover5"
                    alt="hovericon5"
                  />
                  <img
                    src="img/hover6.png"
                    className="hover6"
                    alt="hovericon6"
                  />
                  <img
                    src="img/hover7.png"
                    className="hover7"
                    alt="hovericon7"
                  />
                </div>
                <div>
                  <img
                    onMouseEnter={() => handleHoverImage4(true)}
                    onMouseLeave={() => handleHoverImage4(false)}
                    className="creative-innovation"
                    alt="Creative innovation"
                    src="img/icreative.png"
                  />
                  <img
                    onMouseEnter={() => handleHoverImage5(true)}
                    onMouseLeave={() => handleHoverImage5(false)}
                    className="sound"
                    alt="Sound"
                    src="img/isound.png"
                  />
                  <img
                    onMouseEnter={() => handleHoverImage5(true)}
                    onMouseLeave={() => handleHoverImage5(false)}
                    className="smell"
                    alt="Smell"
                    src="img/ismell.png"
                  />
                  <img
                    onMouseEnter={() => handleHoverImage1(true)}
                    onMouseLeave={() => handleHoverImage1(false)}
                    className="element-goal"
                    alt="Element goal"
                    src="img/igoal.png"
                  />
                  <img
                    onMouseEnter={() => handleHoverImage2(true)}
                    onMouseLeave={() => handleHoverImage2(false)}
                    className="two-directions"
                    alt="Two directions"
                    src="img/itwo.png"
                  />
                  <img
                    onMouseEnter={() => handleHoverImage3(true)}
                    onMouseLeave={() => handleHoverImage3(false)}
                    className="data"
                    alt="Data"
                    src="img/idata.png"
                  />
                  <img
                    onMouseEnter={() => handleHoverImage3(true)}
                    onMouseLeave={() => handleHoverImage3(false)}
                    className="idea"
                    alt="Idea"
                    src="img/iidea.png"
                  />
                  <img
                    onMouseEnter={() => handleHoverImage3(true)}
                    onMouseLeave={() => handleHoverImage3(false)}
                    className="technology"
                    alt="Technology"
                    src="img/itech.png"
                  />
                  <img
                    onMouseEnter={() => handleHoverImage6(true)}
                    onMouseLeave={() => handleHoverImage6(false)}
                    className="vector"
                    alt="Vectoor"
                    src="img/iv.png"
                  />
                  <img
                    onMouseEnter={() => handleHoverImage7(true)}
                    onMouseLeave={() => handleHoverImage7(false)}
                    className="th-advantage-people"
                    alt="Th advantage people"
                    src="img/ipeople.png"
                  />
                   <Link to="/home">
      <img
                    src="img/continue.png"
                    className="continue"
                    alt="hovericon5"
                  />
        </Link>
                  <img
                    onMouseEnter={() => handleHoverImage5(true)}
                    onMouseLeave={() => handleHoverImage5(false)}
                    className="sight"
                    alt="Sight"
                    src="img/isight.png"
                  />
                  <img className="filler" alt="Fill" src="img/ix.png" />
                  <img
                    className="filler-2"
                    alt="Fillr"
                    src="img/ifilter1.png"
                  />
                  <img className="vector-3" alt="Vector" src="img/ibox.png" />
                </div>
                <div className="overlap-2">
                  <img
                    className="individual"
                    alt="Individual"
                    src="img/iindividual.png"
                  />
                    <img
                    onMouseEnter={() => handleHoverImage4(true)}
                    onMouseLeave={() => handleHoverImage4(false)}
                    className="HR"
                    alt="Hr"
                    src="img/ihr.png"
                  />
                  <img
                    onMouseEnter={() => handleHoverImage5(true)}
                    onMouseLeave={() => handleHoverImage5(false)}
                    className="touch"
                    alt="Touch"
                    src="img/itouch.png"
                  />
                  <img
                    className="filler-3"
                    alt="Filler"
                    src="img/ifilter1.png"
                  />

                </div>
                <img
                  onMouseEnter={() => handleHoverImage4(true)}
                  onMouseLeave={() => handleHoverImage4(false)}
                  className="brand-management"
                  alt="Brand management"
                  src="img/ibrand.png"
                />
                <img
                  onMouseEnter={() => handleHoverImage4(true)}
                  onMouseLeave={() => handleHoverImage4(false)}
                  className="finance"
                  alt="Finance"
                  src="img/ifinance.png"
                />
                <img
                  onMouseEnter={() => handleHoverImage5(true)}
                  onMouseLeave={() => handleHoverImage5(false)}
                  className="tatste"
                  alt="Tatste"
                  src="img/itatste.png"
                />
                <img className="vector-4" alt="Vector" src="img/ioo.png" />
                <img className="filler-4" alt="Fill" src="img/ifilter2.png" />
                <img className="filler-5" alt="Filler" src="img/io.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-home">
          <div
            className={`${
              hoveredImage1m ? "imagehover1m" : "overlap-group-wrapper"
            } ${hoveredImage2m ? "imagehover2m" : "overlap-group-wrapper"}
            ${hoveredImage3m ? "imagehover3m" : "overlap-group-wrapper"}
            ${hoveredImage4m ? "imagehover4m" : "overlap-group-wrapper"}
            ${hoveredImage5m ? "imagehover5m" : "overlap-group-wrapper"}
            ${hoveredImage6m ? "imagehover6m" : "overlap-group-wrapper"}
            ${hoveredImage7m ? "imagehover7m" : "overlap-group-wrapper"}
            `}
          >
            <div className="overlap-group">
              <img
                src="img/hoverm1.png"
                className="hover1m"
                alt="hovericonm1"
              />
              <img
                src="img/hoverm2.png"
                className="hover2m"
                alt="hovericonm2"
              />
              <img
                src="img/hoverm3.png"
                className="hover3m"
                alt="hovericonm3"
              />
              <img
                src="img/hoverm4.png"
                className="hover4m"
                alt="hovericonm4"
              />
              <img
                src="img/hoverm5.png"
                className="hover5m"
                alt="hovericonm5"
              />
              <img
                src="img/hoverm6.png"
                className="hover6m"
                alt="hovericonm6"
              />
              <img
                src="img/hoverm7.png"
                className="hover7m"
                alt="hovericonm7"
              />
              <div className="overlap">
                <img
                  onMouseEnter={() => handleHoverImage4m(true)}
                  onMouseLeave={() => handleHoverImage4m(false)}
                  className="creatives"
                  alt="Creatives"
                  src="img/icreative.png"
                />
                <img
                  className="individual"
                  alt="Individual"
                  src="img/iindividual.png"
                />
                <img
                  onMouseEnter={() => handleHoverImage4m(true)}
                  onMouseLeave={() => handleHoverImage4m(false)}
                  className="hr"
                  alt="Hr"
                  src="img/ihr.png"
                />
                <img
                  onMouseEnter={() => handleHoverImage5m(true)}
                  onMouseLeave={() => handleHoverImage5m(false)}
                  className="tatste"
                  alt="Tatste"
                  src="img/itatste.png"
                />
                <img
                  onMouseEnter={() => handleHoverImage5m(true)}
                  onMouseLeave={() => handleHoverImage5m(false)}
                  className="smell"
                  alt="Smell"
                  src="img/ismell.png"
                />
                <img
                  onMouseEnter={() => handleHoverImage1m(true)}
                  onMouseLeave={() => handleHoverImage1m(false)}
                  className="element-goal"
                  alt="Element goal"
                  src="img/igoal.png"
                />
                <img
                  onMouseEnter={() => handleHoverImage2m(true)}
                  onMouseLeave={() => handleHoverImage2m(false)}
                  className="two-directions"
                  alt="Two directions"
                  src="img/itwo.png"
                />
                <img
                  onMouseEnter={() => handleHoverImage3m(true)}
                  onMouseLeave={() => handleHoverImage3m(false)}
                  className="idea"
                  alt="Idea"
                  src="img/iidea.png"
                />
                <img
                  onMouseEnter={() => handleHoverImage3m(true)}
                  onMouseLeave={() => handleHoverImage3m(false)}
                  className="technology"
                  alt="Technology"
                  src="img/itech.png"
                />
                <img
                  onMouseEnter={() => handleHoverImage6m(true)}
                  onMouseLeave={() => handleHoverImage6m(false)}
                  className="iv"
                  alt="Iv"
                  src="img/Iv2.png"
                />
                <img
                  onMouseEnter={() => handleHoverImage5m(true)}
                  onMouseLeave={() => handleHoverImage5m(false)}
                  className="touch"
                  alt="Touch"
                  src="img/touch2.png"
                />
                <img
                  onMouseEnter={() => handleHoverImage7m(true)}
                  onMouseLeave={() => handleHoverImage7m(false)}
                  className="people"
                  alt="People"
                  src="img/people2.png"
                />
                
                <img
                  onMouseEnter={() => handleHoverImage5m(true)}
                  onMouseLeave={() => handleHoverImage5m(false)}
                  className="sight"
                  alt="Sight"
                  src="img/isight.png"
                />
                <img className="filter" alt="Filter" src="img/ifilter1.png" />
                <img className="group" alt="Group" src="img/ifilter1.png" />
                <img className="img" alt="Filter" src="img/filt.png" />
              </div>
              <div className="overlap2">
                <img
                  onMouseEnter={() => handleHoverImage4m(true)}
                  onMouseLeave={() => handleHoverImage4m(false)}
                  className="brand-management"
                  alt="Brand management"
                  src="img/ibrand.png"
                />
                <img
                  onMouseEnter={() => handleHoverImage4m(true)}
                  onMouseLeave={() => handleHoverImage4m(false)}
                  className="finance"
                  alt="Finance"
                  src="img/ifinance.png"
                />
                <img
                  onMouseEnter={() => handleHoverImage5m(true)}
                  onMouseLeave={() => handleHoverImage5m(false)}
                  className="sound"
                  alt="Sound"
                  src="img/sound.png"
                />
                <img
                  onMouseEnter={() => handleHoverImage3m(true)}
                  onMouseLeave={() => handleHoverImage3m(false)}
                  id="dt"
                  className="data"
                  alt="Data"
                  src="img/idata.png"
                />
              </div>
              <Link to="/home">
      <img
                    src="img/continue.png"
                    className="continue"
                    alt="hovericon5"
                  />
        </Link>
            </div>
          </div>
        </div>
      </div>
     

    </Land>
  );
};

const Land = styled.div`
  background-color: #030c2d;
  overflow: hidden;
  .element-i-home-our-story {
    img {
      opacity: 1;
      transition: opacity 4s ease; // Adjust the duration as needed
    }
    .continue{

    }
    .hover1,
    .hover2,
    .hover3,
    .hover4,
    .hover5,
    .hover6,
    .hover7 {
      opacity: 0;
    }
  }
  .imagehover1 {
    .technology,
    .tatste,
    .finance,
    .data,
    .filler,
    .brand-management,
    .creative-innovation,
    .vector,
    .th-advantage-people,
    .filler-2,
    .vector-4,
    .technology,
    .sound,
    .sight,
    .filler-3,
    .HR,
    .filler-5,
    .individual,
    .vector-3,
    .idea,
    .filler-4,
    .touch,
    .two-directions,
    .smell,
    .vector-2 {
      opacity: 0.1;
    }
    .hover1 {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }

  .imagehover2 {
    .technology,
    .tatste,
    .finance,
    .data,
    .element-goal,
    .filler,
    .brand-management,
    .creative-innovation,
    .vector,
    .th-advantage-people,
    .filler-2,
    .vector-4,
    .technology,
    .sound,
    .sight,
    .filler-3,
    .HR,
    .filler-5,
    .individual,
    .vector-3,
    .idea,
    .filler-4,
    .touch,
    .smell,
    .vector-2 {
      opacity: 0.1;
    }
    .hover2 {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }

  .imagehover3 {
    .tatste,
    .finance,
    .element-goal,
    .filler,
    .brand-management,
    .creative-innovation,
    .vector,
    .two-directions,
    .th-advantage-people,
    .filler-2,
    .vector-4,
    .sound,
    .sight,
    .filler-3,
    .HR,
    .filler-5,
    .individual,
    .vector-3,
    .filler-4,
    .touch,
    .smell,
    .vector-2 {
      opacity: 0.1;
    }
    .hover3 {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }

  .imagehover4 {
    .tatste,
    .element-goal,
    .filler,
    .vector,
    .two-directions,
    .th-advantage-people,
    .filler-2,
    .vector-4,
    .sound,
    .sight,
    .filler-3,
    .filler-5,
    .individual,
    .data,
    .technology,
    .idea,
    .vector-3,
    .filler-4,
    .touch,
    .smell,
    .vector-2 {
      opacity: 0.1;
    }
    .hover4 {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }

  .imagehover5 {
    .element-goal,
    .brand-management,
    .creative-innovation,
    .HR,
    .finance,
    .filler,
    .vector,
    .two-directions,
    .th-advantage-people,
    .filler-2,
    .vector-4,
    .filler-3,
    .filler-5,
    .individual,
    .data,
    .technology,
    .idea,
    .vector-3,
    .filler-4,
    .vector-2 {
      opacity: 0.1;
    }
    .hover5 {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }

  .imagehover6 {
    .technology,
    .element-goal,
    .tatste,
    .finance,
    .data,
    .filler,
    .brand-management,
    .creative-innovation,
    .th-advantage-people,
    .filler-2,
    .vector-4,
    .technology,
    .sound,
    .sight,
    .filler-3,
    .HR,
    .filler-5,
    .individual,
    .vector-3,
    .idea,
    .filler-4,
    .touch,
    .two-directions,
    .smell,
    .vector-2 {
      opacity: 0.1;
    }
    .hover6 {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }

  .imagehover7 {
    .technology,
    .vector,
    .element-goal,
    .tatste,
    .finance,
    .data,
    .filler,
    .brand-management,
    .creative-innovation,
    .filler-2,
    .vector-4,
    .technology,
    .sound,
    .sight,
    .filler-3,
    .HR,
    .filler-5,
    .individual,
    .vector-3,
    .idea,
    .filler-4,
    .touch,
    .two-directions,
    .smell,
    .vector-2 {
      opacity: 0.1;
    }

    .hover7 {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }

  @media (max-width: 540px) {
    .overlap-group-wrapper {
      img {
        opacity: 1;
        transition: opacity 4s ease; // Adjust the duration as needed
      }
      .hover1m,
      .hover2m,
      .hover3m,
      .hover4m,
      .hover5m,
      .hover6m,
      .hover7m {
        opacity: 0;
      }
    }
    .imagehover1m {
      .creatives,
      .two-directions,
      .individual,
      .hr,
      .tatste,
      .smell,
      .idea,
      .technology,
      .iv,
      .touch,
      .people,
      .sight,
      .filter,
      .group,
      .img,
      .brand-management,
      .finance,
      .sound,
      .data {
        opacity: 0.1;
      }

      .hover1m {
        opacity: 1;
      }
      img {
        transition: opacity 2s ease;
      }
    }
  //one end

  .imagehover2m {
    .creatives,
    .element-goal,
    .individual,
    .hr,
    .tatste,
    .smell,
    .idea,
    .technology,
    .iv,
    .touch,
    .people,
    .sight,
    .filter,
    .group,
    .img,
    .brand-management,
    .finance,
    .sound,
    .data {
      opacity: 0.1;
    }
    .hover2m {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }
  //twoend

  .imagehover3m {
    .creatives,
    .two-directions,
    .individual,
    .element-goal,
    .hr,
    .tatste,
    .smell,
    .iv,
    .touch,
    .people,
    .sight,
    .filter,
    .group,
    .img,
    .brand-management,
    .finance,
    .sound,
    #datas .data {
      opacity: 0.1;
    }
    .hover3m {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }
  // thirdend
  .imagehover4m {
    .idea,
    .technology,
    .element-goal,
    .individual,
    .two-directions,
    .tatste,
    .smell,
    .iv,
    .touch,
    .people,
    .sight,
    .filter,
    .group,
    .img,
    .sound,
    .data {
      opacity: 0.1;
    }
    .hover4m {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }
  // fourthend
  .imagehover5m {
    .creatives,
    .element-goal,
    .two-directions,
    .individual,
    .hr,
    .idea,
    .technology,
    .iv,
    .people,
    .filter,
    .group,
    .img,
    .brand-management,
    .finance,
    .data {
      opacity: 0.1;
    }
    .hover5m {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }
  // fifthend

  .imagehover6m {
    .idea,
    .technology,
    .element-goal,
    .individual,
    .two-directions,
    .tatste,
    .smell,
    .touch,
    .people,
    .sight,
    .filter,
    .group,
    .img,
    .sound,
    .creatives,
    .individual,
    .hr,
    .touch,
    .img,
    .brand-management,
    .finance,
    .data {
      opacity: 0.1;
    }
    .hover6m {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }
  //  sixth end
  .imagehover7m {
    .idea,
    .technology,
    .element-goal,
    .individual,
    .two-directions,
    .tatste,
    .smell,
    .touch,
    .sight,
    .filter,
    .group,
    .img,
    .sound,
    .creatives,
    .individual,
    .hr,
    .iv,
    .touch,
    .img,
    .brand-management,
    .finance,
    .data {
      opacity: 0.1;
    }
    .hover7m {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }
}

  //end
`;
export default Interactive;

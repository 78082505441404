import styled from "styled-components";

const ThirdSection = () => {

  return (
    <Third>

    </Third>

  );
};

const Third = styled.div`
background-image: url(${process.env.PUBLIC_URL}/img/bgthird.png);
background-size: cover;
background-position: center;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
height:100vh;

  




  @media (max-width: 1280px) { /* For laptop-sized screens */
 
  }





@media (max-width: 1024px) and (max-height: 600px){
    height: 100vh!important;
     }

     @media (max-width: 1024px) and (min-height: 1366px){
height:50vh;
     }
  


@media (max-width: 912px) { /* For tablet-sized screens */
height:50vh;

}

@media (max-width: 640px) { /* For mobile phone-sized screens */
height: 50vh;



}

@media (max-width: 480px) { /* For mobile phone-sized screens */

  
}
`;




export default ThirdSection;

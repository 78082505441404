import styled from "styled-components";

const Button = ({title,backgroundColor,onClick}) => {
  return (
    <VtcButt backgroundColor={backgroundColor} onClick={onClick}>
      <p>{title}</p>
    </VtcButt>
  );
};

const VtcButt = styled.div`
margin-top:5px;
cursor:pointer;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border: none;
border-radius: 41px;
height: 45px;
width: 234px;
background-color: ${(props) => props.backgroundColor || "#F2989A"};
box-shadow: 15px 15px 28px 0px rgba(0, 7, 35, 0.30);


p {
  color: #030C2D important!; /* Set text color to the desired color */
  font-family: "MavenMedium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.24px;
  line-height: normal;
  text-align: center;
  margin: 0; /* Remove any default margin for better centering */
}

  @media (max-width: 1280px) { /* For laptop-sized screens */
height: 40px;
width: 200px;
p {
  font-size: 20px;
}
  }

  @media (max-width: 1024px) and (max-height: 1366px){
    height: 40px;
width: 160px;
p {
  font-size: 14px;
}
  }
  
  @media (max-width: 1024px) and (max-height: 600px){
border-radius: 41px;
height: 35px;
width: 180px;

p {
  font-size: 18px;
}
  }
  @media (max-width: 912px){
    height: 30px;
width: 140px;
p {
  font-size: 14px;
}
  }
  
  @media (max-width: 640px) { /* For tablet-sized screens */
  height: 30px;
width: 140px;
p {
  font-size: 14px;
}
  }

  @media (max-width: 480px) { /* For mobile phone-sized screens */
  height: 30px;
width: 150px;
p {
  font-size: 7px;
}
  }

`;


export default Button;

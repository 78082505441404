import styled from "styled-components";
import HeaderText2 from "../../Text/HeaderText2";
import DreamersButton from "./DreamersButton";
import { NavLink } from "react-router-dom";

const DreamersSection = () => {
  return (
    <Dreamers>
      <div className="container div">
          <HeaderText2 title="Meet The" title2="Dream Makers" color="white" />
          <p>
            At 7i, we are all about our people, be they staff or client, but we
            are particularly proud of our award-winning team of expert dream
            makers.
          </p>
          <div className="bt">
          <NavLink
            to="/dreamers"
            // target="_blank"
            // rel="noopener noreferrer"
            className="custom-link"
          >
            <DreamersButton />
          </NavLink>
            </div>
      </div>
    </Dreamers>
  );
};

const Dreamers = styled.div`
  /* background-image: url(${process.env.PUBLIC_URL}/img/bgdream.png); */
  background-image: url(${process.env.PUBLIC_URL}/img/teambg.png);

  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  // justify-content:center;
  // align-items:center;
  height: 100vh;
  .bt{
    width:190px;
  }
  .div {
    margin-top: 50px;
  }


  .custom-link {
    /* Remove underline from the anchor element */
    text-decoration: none;
    color: #030c2d;
  }

  p {
    color: #030c2d;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    letter-spacing: -0.3px;
    font-family: "MavenRegular",Times;
    padding-right: 700px;
  }
  @media (max-width: 1280px) {
    /* For laptop-sized screens */
    p {
      color: #030c2d;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      letter-spacing: -0.3px;
      padding-right: 350px;
    }
  }

  @media (max-width: 576px) {
    /* For mobile phone-sized screens */
    //   height:40vh;
    //   background-image: url(${process.env.PUBLIC_URL}/img/bgaward.png);
    background-size: cover;
    background-position: center;
  }
  //   @media (max-width: 1200px) { /* For laptop-sized screens */
  // height: 100vh;
  // }

  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh !important;
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 50vh;
  }

  @media (max-width: 912px) {
    /* For tablet-sized screens */
    height: 50vh;
    p {
      color: #030c2d;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      letter-spacing: -0.3px;
      padding-right: 0px;
    }
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    height: 50vh;
    .bt{
    width:190px;
  }
    p {
      color: #030c2d;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      letter-spacing: -0.3px;
      padding-right: 0px;
    }
    .div {
      margin-top: 5px;
    }
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    .bt{
    width:140px;
  }
    height: 50vh;
  }
`;


export default DreamersSection;

import styled from "styled-components";

const WhoButton = ({ title ,onClick}) => {
  return (
    <WhoButt onClick={onClick}>
      <span>{title}</span>
    </WhoButt>
  );
};

const WhoButt = styled.div`
  border: 2px solid #01eaff;
  background: #030c2d;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 41px;
  box-shadow: 0px 4px 4px #00000040;
  height: 50px;
  width: 190px;

  span {
    color: #01eaff;
    font-family: "MavenMedium",Times;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.24px;
    line-height: normal;
    text-align: center;
    margin: 0; /* Remove any default margin for better centering */
  }

  @media (max-width: 1200px) {
    /* For laptop-sized screens */
  }
  @media (max-width: 912px) {
  }

  @media (max-width: 640px) {
    /* For tablet-sized screens */
    border: 2px solid #01eaff;
    background: #030c2d;
    margin-top: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 41px;
    box-shadow: 0px 4px 4px #00000040;
    height: 50px;
    width: 190px;

    span {
      color: #01eaff;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: -0.24px;
      line-height: normal;
      text-align: center;
      margin: 0; /* Remove any default margin for better centering */
    }
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    margin-top: 0px;
    color: #01eaff;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 140px;

    span {
      color: #01eaff;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: -0.24px;
      line-height: normal;
      text-align: center;
      margin: 0; /* Remove any default margin for better centering */
    }
  }
`;

export default WhoButton;

import styled from "styled-components";
import HeaderText from "../Text/HeaderText";
import Navbar from "../Navbar/Navbar";
const WorkSection = () => {
  return (
    <Work>
      <Navbar />
      <div className="container div">
        <div>
          <HeaderText title="Works" title1="Works" color="white" />
        </div>
        <div className="imgbox">
          <ImageWIcon src="/img/data.png" alt="dataicon"></ImageWIcon>
          <ImageWIcon src="/img/idea.png" alt="ideaicon"></ImageWIcon>
          <ImageWIcon
            src="/img/technology.png"
            alt="techicon"
          ></ImageWIcon>
        </div>
      </div>
    </Work>
  );
};
const ImageWIcon = styled.img`
  width: auto;
  height: 150px;

  @media (max-width: 912px) {
    /* For tablet-sized screens */
    width: auto;
    height: 80px;
  }
  @media (max-width: 912px) {
    /* For tablet-sized screens */
    width: auto;
    height: 80px;
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    width: auto;
    height: 80px;
  }
  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    width: auto;
    height: 100px;
  }
`;
const Work = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/bgabout.png);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  .div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .imgbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  @media (max-width: 1200px) {
    /* For laptop-sized screens */
    height: 100vh;
  }
  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh;
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 50vh;
  }
  @media (max-width: 912px) {
    /* For tablet-sized screens */
    height: 50vh;
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    height: 50vh;
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    height: 100vh;

    .div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .imgbox {
      display: flex;
      flex-direction: column;
      // justify-content:space-between;
      align-items: center;
      gap: 2rem;
      margin-top: 50px;
    }
  }
`;

export default WorkSection;
